import {
  WppActionButton,
  WppIconArrow,
  WppIconMore,
  WppListItem,
  WppMenuContext,
  WppMenuGroup,
} from '@platform-ui-kit/components-library-react'
import { AppInstanceShort } from '@wpp-open/core'
import { ComponentProps, ElementType } from 'react'
import { useTranslation } from 'react-i18next'

import { useUpdateAppsGroupPosition } from 'hooks/useUpdateAppsGroupPosition'
import { excludeFalsy } from 'utils/common'

type Props = GroupProps | SubgroupProps

interface DefaultProps {
  assignmentId?: string
  currentIndex: number
}

interface GroupProps extends DefaultProps {
  current: NonNullable<AppInstanceShort['group']>
  source: {
    group: NonNullable<AppInstanceShort['group']>
    appInstances: AppInstanceShort[]
  }[]
  type: 'groups'
}

interface SubgroupProps extends DefaultProps {
  current: NonNullable<AppInstanceShort['subgroup']>
  source: {
    subgroup: NonNullable<AppInstanceShort['subgroup']>
    appInstances: AppInstanceShort[]
  }[]
  type: 'subgroups'
}

interface MenuItemProps<E extends ElementType> {
  label: string
  icon: E
  disabled?: boolean
  iconProps?: ComponentProps<E>
  onClick: () => void
  withDivider?: boolean
}

export const GroupMenuItems = ({ current, source, currentIndex, assignmentId, type }: Props) => {
  const { t } = useTranslation()
  const { updateAppsGroupPosition, isInstanceUpdating } = useUpdateAppsGroupPosition()

  const groupMenuItems = () => {
    return [
      {
        label: t('os.admin.apps.move_up'),
        icon: WppIconArrow,
        disabled: source.length === 1 || currentIndex === 0,
        iconProps: { direction: 'top' },
        withDivider: false,
        onClick: () =>
          updateAppsGroupPosition({
            type,
            current,
            target: type === 'groups' ? source[currentIndex - 1].group : source[currentIndex - 1].subgroup,
            assignmentId,
          }),
      } satisfies MenuItemProps<typeof WppIconArrow>,
      {
        label: t('os.admin.apps.move_down'),
        icon: WppIconArrow,
        disabled: source.length === 1 || currentIndex + 1 === source.length,
        iconProps: { direction: 'down' },
        withDivider: false,
        onClick: () =>
          updateAppsGroupPosition({
            type,
            current,
            target: type === 'groups' ? source[currentIndex + 1].group : source[currentIndex + 1].subgroup,
            assignmentId,
          }),
      } satisfies MenuItemProps<typeof WppIconArrow>,
    ].filter(excludeFalsy)
  }

  return (
    <div
      onClick={e => {
        e.stopPropagation()
      }}
    >
      <WppMenuContext>
        <WppActionButton
          slot="trigger-element"
          variant="secondary"
          loading={isInstanceUpdating}
          disabled={isInstanceUpdating}
          data-testid="tree-list-item-menu-trigger"
        >
          <WppIconMore direction="horizontal" slot="icon-start" />
        </WppActionButton>
        {groupMenuItems().map(({ label, icon: Icon, disabled, onClick, iconProps, withDivider }) => {
          return withDivider ? (
            <WppMenuGroup withDivider key={label}>
              <WppListItem disabled={disabled} onWppChangeListItem={onClick}>
                <Icon slot="left" {...iconProps} />
                <span slot="label">{label}</span>
              </WppListItem>
            </WppMenuGroup>
          ) : (
            <WppListItem key={label} disabled={disabled} onWppChangeListItem={onClick}>
              <Icon slot="left" {...iconProps} />
              <span slot="label">{label}</span>
            </WppListItem>
          )
        })}
      </WppMenuContext>
    </div>
  )
}
