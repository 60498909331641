import { facadeApi } from 'api'
import { SortOrder } from 'api/common/types'
import { skipEmptyParams } from 'api/common/utils'
import { FileCategory } from 'pages/files/types'
import { AssignmentType, FileItemType, FileTreeItem } from 'types/files/files'

interface Params {
  tenantId: string
  assignmentId?: string
  assignmentType?: AssignmentType
  name?: string
  parentId?: string
  sortField?: string
  sortDirection?: SortOrder
  isFavorite?: boolean
  recursive?: boolean
  createdBy?: string
  type?: FileItemType
  category?: Omit<FileCategory, FileCategory.ALL>
}

export enum FilesSearchSortBy {
  name = 'name',
  createdAt = 'createdAt',
  size = 'size',
  fileType = 'fileType',
  createdBy = 'createdBy',
}

export const fetchFilesSearchApi = ({
  assignmentId,
  assignmentType,
  name,
  parentId,
  sortDirection,
  sortField,
  isFavorite,
  tenantId,
  recursive,
  createdBy,
  type,
  category,
}: Params) =>
  facadeApi.post<FileTreeItem[]>(
    'file-manager/v1/files/search',
    {
      assignmentId,
      assignmentType,
      ...skipEmptyParams({
        name,
        parentId,
        sortDirection,
        sortField,
        isFavorite,
        recursive,
        createdBy,
        type,
        category,
      }),
    },
    {
      headers: {
        'X-Tenant-Id': tenantId,
      },
    },
  )
