import { facadeApi } from 'api'

export interface ImportCalendarWidgetEventsResult {
  eventsUploaded: number
}

interface Params {
  hubId: string
  calendarId: string
  file: File
}

export const importCalendarWidgetEvents = ({ hubId, calendarId, file }: Params) => {
  const formData = new FormData()

  formData.append('file', file)

  return facadeApi.put<ImportCalendarWidgetEventsResult>(
    `/hubs/${hubId}/calendars/${calendarId}/events/bulk/ics`,
    formData,
  )
}
