import { MayBeNull } from '@wpp-open/core'

export interface Metadata {
  name: string
  category: MayBeNull<string>
  contentType: MayBeNull<string>
  size: number
}

export enum AssignmentType {
  HUB = 'HUB',
}

export enum FileItemType {
  FOLDER = 'FOLDER',
  REGULAR = 'REGULAR',
  SYMLINK = 'SYMLINK',
  SYSTEM_FOLDER = 'SYSTEM_FOLDER',
}

export enum FileItemStatus {
  ACTIVE = 'ACTIVE',
  DRAFT = 'DRAFT',
  INACTIVE = 'INACTIVE',
}

export interface FileItem {
  id: string
  name: string
  accountId: string
  type: FileItemType
  status: FileItemStatus
  description: string
  metadata: Metadata
  assignmentId: string
  assignmentType: AssignmentType.HUB
  createdAt: string
  createdBy: string
  updatedAt: MayBeNull<string>
  updatedBy: MayBeNull<string>
  parentId: string
  isFavorite: boolean
  isPinned: boolean
  allowInAiAssistant: boolean
}

export interface FileTreeItem extends FileItem {
  children: FileTreeItem[]
}

export interface CreateFolderDTO {
  name: string
  parentId?: string
  status?: FileItemStatus
  description?: ''
  assignmentId: string
  assignmentType: AssignmentType
  isFavorite?: boolean
}

export interface UploadFileDataDTO extends FileUpdateDTO {
  parentId?: string
  assignmentId: string
  assignmentType: AssignmentType
  contentType: string
}

export interface FileUpdateDTO {
  name?: string
  description?: string
  isFavorite?: boolean
  isPinned?: boolean
  allowInAiAssistant?: boolean
}

export interface FileTag {
  id: string
  name: string
  assignmentId: string
  assignmentType: AssignmentType
  description: string
  accountId: string
  createdAt: string
  createdBy: string
  updatedAt: string
  updatedBy: string
}

export interface DownloadFileInfo {
  url: string
}

export interface UploadFileInfo {
  url: string
  maxFileSize: number
  method: string
}
