import { DefaultHierarchyLevelType, TenantType } from '@wpp-open/core'

import { useMePermissionByAccountsAndPermissionsApi } from 'api/alphaZulu/queries/useMePermissionByAccountsAndPermissionsApi'
import { useHierarchyTreeApi } from 'api/navigation/queries/useHierarchyTreeApi'
import { Permission } from 'constants/permission'
import { useWorkspaceAccountIds } from 'hooks/useWorkspaceAccountIds'
import { useCurrentTenantData } from 'providers/currentTenantData/CurrentTenantDataContext'

export const useUserCanManageHierarchy = (isLayerAdminPage = false) => {
  const {
    currentTenant: { id, azId, tenantType },
    navigationHierarchy,
  } = useCurrentTenantData()

  const { isLoading, data: hierarchyTree } = useHierarchyTreeApi({
    params: {
      tenantId: id,
    },
  })
  const accountIds = useWorkspaceAccountIds(hierarchyTree)
  const {
    data: hierarchyManagePermissions,
    isLoading: isPermissionsLoading,
    isFetched,
  } = useMePermissionByAccountsAndPermissionsApi({
    params: {
      account_ids: accountIds,
      perms: [Permission.OS_WORKSPACE_MANAGE],
      perm_prefixes: [Permission.OS_PREFIX],
    },
    enabled: !!hierarchyTree && isLayerAdminPage,
  })

  const nodesOnClientsHierarchyTypeLevel = hierarchyTree
    ? Object.values(hierarchyTree.mapping).filter(
        node => node.azId !== azId && node.type === DefaultHierarchyLevelType.Client,
      )
    : []

  // The parameter is responsible for giving acces to "Client" hierarchy level
  // which is true for all tenants not of type "Agency"
  // also if tenant has clients  or navigation include client level from the past configurations (until all tenants move to the new type structure)
  const isHasAccesToClientsHierarchyTypeLevel =
    tenantType !== TenantType.Agency ||
    !!nodesOnClientsHierarchyTypeLevel.length ||
    navigationHierarchy.some(level => level.type === DefaultHierarchyLevelType.Client)

  return {
    isUserCanManage: !isFetched || !!hierarchyManagePermissions?.length,
    hierarchyManagePermissions,
    hierarchyTree,
    isLoading,
    isPermissionsLoading,
    isHasAccesToClientsHierarchyTypeLevel,
  }
}
