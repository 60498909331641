import { keepPreviousData } from '@tanstack/react-query'
import {
  ChildHierarchyLevelType,
  HierarchyContainerNodeId,
  HierarchyLevel,
  DefaultHierarchyLevelType,
  HierarchyNode,
  MayBeNull,
  NavigationTree,
  NavigationTreeNode,
  Tenant,
  isDefaultHierarchyLevelType,
  HierarchyLevelType,
  getNavigationNodeType,
  HierarchyNodeType,
  HierarchyCustomNodeType,
  PermissionsRecord,
} from '@wpp-open/core'
import { useCallback, useMemo } from 'react'
import { useTranslation } from 'react-i18next'

import { useGenerateMasterDataDownloadUrlsApi } from 'api/attachments/queries/useGenerateMasterDataDownloadUrlsApi'
import { ApiQueryKeys } from 'constants/apiQueryKeys'
import { DEFAULT_PLURAL_COUNT } from 'constants/i18n'
import { useCurrentTenantData } from 'providers/currentTenantData/CurrentTenantDataContext'
import { queryClient } from 'providers/osQueryClient/utils'
import { HierarchyTree } from 'types/hierarchy/hierarchy'
import { HierarchyTranslationKey } from 'types/navigation/navigation'

export type OnlyHierarchyNodesTreeTreeMapping = Record<string, HierarchyNode>

export interface OnlyHierarchyNodesTree {
  rootId: string
  mapping: OnlyHierarchyNodesTreeTreeMapping
}

export const getHierarchyLevelTKey = (levelType: DefaultHierarchyLevelType) =>
  levelType === DefaultHierarchyLevelType.Tenant
    ? 'os.common.navigation.os'
    : (`os.common.master_data.entities.${levelType.toLocaleLowerCase() as HierarchyTranslationKey}` as const)

export const getNextHierarchyLevelType = ({
  type,
  hierarchyLevels,
}: {
  type: HierarchyLevelType
  hierarchyLevels: HierarchyLevel[]
}) => {
  const nextLevelIndex = Math.min(
    hierarchyLevels.findIndex(level => level.type === type) + 1,
    hierarchyLevels.length - 1,
  )

  return hierarchyLevels[nextLevelIndex].type
}

export const useGetHierarchyLevelLabel = () => {
  const { t } = useTranslation()

  return useCallback(
    ({ levelType, isPlural = false }: { levelType: MayBeNull<HierarchyLevelType>; isPlural?: boolean }) => {
      if (levelType) {
        if (isDefaultHierarchyLevelType(levelType)) {
          return t(getHierarchyLevelTKey(levelType), {
            count: isPlural ? DEFAULT_PLURAL_COUNT : 1,
          })
        }

        return levelType
      }

      return t('os.common.navigation.os')
    },
    [t],
  )
}

export const useGetHierarchyNodeLabel = () => {
  const { t } = useTranslation()

  return useCallback(
    ({ nodeType }: { nodeType: MayBeNull<HierarchyNodeType> }) => {
      if (nodeType) {
        if (nodeType === HierarchyCustomNodeType) {
          return t('os.common.navigation.node_type_custom')
        }

        return t(getHierarchyLevelTKey(nodeType))
      }

      return t('os.common.navigation.os')
    },
    [t],
  )
}

export type Tree = NavigationTree | HierarchyTree | OnlyHierarchyNodesTree
type MappingNode<T extends Tree> = T['mapping'][string]

export interface PointerNode<T extends Tree> {
  parent: MayBeNull<PointerNode<T>>
  nodeId: string
  node: MappingNode<T>
  children: PointerNode<T>[]
  canManage?: boolean
  isVisible?: boolean
}

export interface NodesMapping<T extends Tree> {
  [nodeId: string]: PointerNode<T>
}

export const getWorkspacePointerNodes = <T extends Tree>({
  pointerNode,
  navigationHierarchy,
}: {
  pointerNode: PointerNode<T>
  navigationHierarchy: HierarchyLevel<ChildHierarchyLevelType>[]
}): PointerNode<T>[] => {
  const addToResultIfHierarchyNode = (pointerNode: PointerNode<T>) => {
    if (navigationHierarchy.some(({ type }) => type === getNavigationNodeType(pointerNode.node))) {
      result.unshift(pointerNode)
    }
  }

  const result: PointerNode<T>[] = []
  let current: MayBeNull<PointerNode<T>> = pointerNode

  do {
    addToResultIfHierarchyNode(current)
    current = current.parent
  } while (current)

  return result
}

export const useGetFirstLevelNodesLogoUrl = <T extends Tree>({
  tree,
  hierarchyLevels,
}: {
  tree: T
  hierarchyLevels: HierarchyLevel<ChildHierarchyLevelType>[]
}) => {
  const { mapping } = tree

  const keys = useMemo(
    () =>
      (mapping[HierarchyContainerNodeId]?.children || [])
        .filter(nodeId => {
          const type = getNavigationNodeType(mapping[nodeId])
          const { logoThumbnail } = mapping[nodeId]

          return type === hierarchyLevels[0].type && logoThumbnail
        })
        .map(nodeId => mapping[nodeId].logoThumbnail!.key),
    [mapping, hierarchyLevels],
  )

  const { data } = useGenerateMasterDataDownloadUrlsApi({
    params: {
      keys,
    },
    staleTime: 30000,
    placeholderData: keepPreviousData,
    enabled: keys.length > 0,
  })

  const logoUrlsMap = useMemo(() => Object.fromEntries(data.map(({ key, signed_url }) => [key, signed_url])), [data])

  return (key?: string) => (key && logoUrlsMap[key]) || ''
}

export const refetchTreesQueries = () =>
  Promise.all([
    queryClient.refetchQueries({ queryKey: [ApiQueryKeys.NAVIGATION_TREE] }),
    queryClient.refetchQueries({ queryKey: [ApiQueryKeys.HIERARCHY_TREE] }),
  ])

export const useNodesMapping = <T extends Tree>(tree: MayBeNull<T>, permissions?: PermissionsRecord[]) =>
  useMemo(() => {
    const result: NodesMapping<T> = {}

    if (!tree) {
      return {}
    }

    const { rootId, mapping } = tree

    const resolve = (nodeId: string, parentPointerNode?: PointerNode<T>): PointerNode<T> => {
      const node = mapping[nodeId] as MappingNode<T>

      const pointerNode: PointerNode<T> = {
        parent: parentPointerNode || null,
        node,
        nodeId,
        children: [],
        canManage:
          parentPointerNode?.canManage ||
          !permissions?.length ||
          permissions.some(permission => permission.account_id === nodeId),
      }

      pointerNode.children = node?.children.length
        ? node.children.map(childNodeId => resolve(childNodeId, pointerNode))
        : []

      pointerNode.isVisible =
        pointerNode.canManage || pointerNode.children.some(child => child.canManage || child.isVisible)

      pointerNode.children = pointerNode.children.filter(({ isVisible, canManage }) => isVisible || canManage)

      result[nodeId] = pointerNode

      return pointerNode
    }

    resolve(rootId)

    return Object.keys(result).reduce((acc, key) => {
      return result[key].isVisible || result[key].canManage ? { ...acc, [key]: result[key] } : { ...acc }
    }, {} as NodesMapping<T>)
  }, [tree, permissions])

export const useTreeWithoutHiddenLevel = <T extends MayBeNull<Tree> | undefined>({
  currentTenant,
  tree,
}: {
  currentTenant: MayBeNull<Tenant>
  tree: T
}): T => {
  const isFirstLevelHidden = currentTenant?.flags.isFirstLevelHidden

  return useMemo(() => {
    if (!tree || !isFirstLevelHidden) {
      return tree
    }

    let result = tree

    if (result.mapping[HierarchyContainerNodeId]) {
      const hiddenLevelId = result.mapping[HierarchyContainerNodeId].children.at(0)

      if (hiddenLevelId && result.mapping[hiddenLevelId]) {
        const hiddenLevelChildren = result.mapping[hiddenLevelId].children
        const updatedMapping = { ...result.mapping }

        delete updatedMapping[hiddenLevelId]

        result = {
          ...result,
          mapping: {
            ...updatedMapping,
            [HierarchyContainerNodeId]: {
              ...result.mapping[HierarchyContainerNodeId],
              children: [...hiddenLevelChildren],
            },
          },
        }
      }
    }

    return result
  }, [isFirstLevelHidden, tree])
}

export const useOnlyHierarchyNodesTree = (outer?: NavigationTree | HierarchyTree): OnlyHierarchyNodesTree => {
  const { navigationTreeWithHiddenLevel } = useCurrentTenantData()
  const tree = outer || navigationTreeWithHiddenLevel

  return useMemo(() => {
    const { rootId, mapping } = tree

    const hierarchyContainerNode = mapping[HierarchyContainerNodeId]

    const result: OnlyHierarchyNodesTreeTreeMapping = {
      [rootId]: { ...mapping[rootId], children: hierarchyContainerNode?.children || [] } as HierarchyNode,
    }

    const walk = ({ children }: NavigationTreeNode) => {
      children.forEach(childId => {
        const childNode = mapping[childId] as HierarchyNode
        result[childId] = childNode

        walk(childNode)
      })
    }

    // Strip out dead leafs
    if (hierarchyContainerNode) {
      walk(hierarchyContainerNode)
    }

    return {
      rootId,
      mapping: result,
    }
  }, [tree])
}
