import { WppButton, WppTypography } from '@platform-ui-kit/components-library-react'
import { Trans, useTranslation } from 'react-i18next'

import { Flex } from 'components/common/flex/Flex'
import { Modal } from 'components/surface/modal/Modal'
import { createNiceModal, NiceModalWrappedProps } from 'utils/createNiceModal'

interface Props extends NiceModalWrappedProps {
  tenantName?: string
}

export const TenantAlreadyRequestedModal = ({ id, isOpen, onClose, onCloseComplete, tenantName }: Props) => {
  const { t } = useTranslation()

  return (
    <Modal
      open={isOpen}
      disableOutsideClick
      size="s"
      onWppModalClose={onClose}
      onWppModalCloseComplete={onCloseComplete}
      data-testid={id}
    >
      <WppTypography slot="header" type="xl-heading">
        {t('os.request_access_modal.tenant_already_requested.title')}
      </WppTypography>

      <div slot="body">
        <WppTypography type="s-body">
          {tenantName ? (
            <Trans
              i18nKey="os.request_access_modal.tenant_already_requested.access_message"
              values={{
                tenantName,
              }}
              components={[
                <WppTypography key="0" type="s-strong">
                  tenantName
                </WppTypography>,
              ]}
            />
          ) : (
            t('os.request_access_modal.tenant_already_requested.sign_up_message')
          )}
        </WppTypography>
      </div>

      <Flex slot="actions" gap={12} justify="end">
        <WppButton variant="primary" size="m" onClick={onClose}>
          {t('os.common.done')}
        </WppButton>
      </Flex>
    </Modal>
  )
}

export const { showModal: showTenantAlreadyRequestedModal } = createNiceModal(
  TenantAlreadyRequestedModal,
  'tenant-already-requested-modal',
)
