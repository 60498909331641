import qs from 'qs'

import { facadeApi } from 'api'

export const bulkDeleteFilesApi = (ids: string[]) =>
  facadeApi.delete('/file-manager/v1/files/bulk', {
    params: {
      'filter[id]': ids,
      hard: true,
    },
    paramsSerializer: {
      serialize: params => qs.stringify(params, { arrayFormat: 'repeat', encode: true }),
    },
  })
