import {
  WppListItem,
  WppTypography,
  WppTag,
  WppActionButton,
  WppIconPin,
} from '@platform-ui-kit/components-library-react'
import { t } from 'i18next'
import { useNavigate } from 'react-router-dom'

import { Avatar } from 'components/common/avatar/Avatar'
import { SvgPinFilledIcon } from 'components/svg/icons/SvgPinFilledIcon'
import { LaunchLocations, ANALYTICS_EVENTS } from 'constants/analytics'
import styles from 'layout/header/Header.module.scss'
import { useOtherTenantsAndUserData } from 'providers/otherTenantsAndUserData/OtherTenantsAndUserDataContext'
import { Hub, HubType } from 'types/hubs/hubs'
import { trackAppOpenAnalytics } from 'utils/analytics'
import { routesManager } from 'utils/routesManager'

interface HUbListItemProps {
  hub: Hub
  checked: boolean
  isHubPinned?: boolean
  isHubDefault?: boolean
  onClickHandler: (hub: Hub) => void
}
export const HubListItem = ({
  hub,
  onClickHandler,
  checked = false,
  isHubPinned = false,
  isHubDefault = false,
}: HUbListItemProps) => {
  const { userDetails } = useOtherTenantsAndUserData()
  const navigate = useNavigate()

  return (
    <WppListItem
      data-testid="header-hub-list-item"
      checked={checked}
      onWppChangeListItem={e => {
        e.stopPropagation()
        trackAppOpenAnalytics({
          userDetails,
          hub_id: hub?.id,
          hub_name: hub?.name,
          is_system_hub: hub?.type === HubType.System,
          launchedFrom: LaunchLocations.NavigationMenu,
          productType: ANALYTICS_EVENTS.OS_MODULES.PRODUCT_TYPE,
          productName: ANALYTICS_EVENTS.OS_MODULES.PRODUCT_NAME.HOME_PAGE,
        })
        if (!checked) {
          navigate(routesManager.hubs.root.url(hub.id))
        }
      }}
      className={styles.hubListItem}
    >
      <Avatar slot="left" name={hub.name} src={hub.logoThumbnail?.url || hub.logoOriginal?.url || undefined} />

      <WppTypography slot="label" type="s-body">
        {hub.name}
      </WppTypography>

      <div slot="right">
        {isHubDefault ? (
          <WppTag label={t('os.common.home')} variant="neutral" />
        ) : (
          <>
            <WppActionButton
              onClick={e => {
                e.preventDefault()
                onClickHandler(hub)
              }}
            >
              {isHubPinned ? (
                <SvgPinFilledIcon />
              ) : (
                <>
                  <WppIconPin className={styles.pinIcon} data-testid="hub-pin-icon" />
                  {!hub.isActive && (
                    <WppTag variant="neutral" className={styles.inActiveTag} label={t('os.hubs.table.inactive')} />
                  )}
                </>
              )}
            </WppActionButton>
          </>
        )}
      </div>
    </WppListItem>
  )
}
