import { FileUploadLocales } from '@platform-ui-kit/components-library'
import { WppFileUpload } from '@platform-ui-kit/components-library-react'
import clsx from 'clsx'
import { ComponentPropsWithoutRef, forwardRef, useImperativeHandle, useMemo, useRef } from 'react'
import { useTranslation } from 'react-i18next'
import { mergeRefs } from 'react-merge-refs'

import styles from 'components/form/formFileUpload/FormFileUpload.module.scss'
import { useField } from 'hooks/form/useField'

type Props = Omit<
  ComponentPropsWithoutRef<typeof WppFileUpload>,
  'name' | 'onFocus' | 'onBlur' | 'onChange' | 'locales' | 'value'
> & {
  name: string
  locales?: Partial<FileUploadLocales>
  infoExtraText?: string
}

export const FormFileUpload = forwardRef<HTMLWppFileUploadElement, Props>(function FormFileUpload(
  {
    id,
    name,
    onWppBlur,
    onWppChange,
    message,
    messageType,
    className,
    locales,
    multiple = false,
    infoExtraText = '',
    ...rest
  },
  ref,
) {
  const { t } = useTranslation()

  const fileLocales = useMemo<FileUploadLocales>(
    () => ({
      label: t('os.common.file_upload.label'),
      text: t('os.common.file_upload.text'),
      info: (accept, size) => t('os.common.file_upload.info', { accept, size, infoExtraText }),
      sizeError: t('os.common.file_upload.size_error'),
      formatError: t('os.common.file_upload.format_error'),
      ...locales,
    }),
    [infoExtraText, locales, t],
  )

  const innerRef = useRef<HTMLWppFileUploadElement>(null)

  const {
    field: { ref: fieldRef, value, onChange, onBlur },
    fieldState: { isTouched, error },
  } = useField({
    name,
  })

  useImperativeHandle(
    fieldRef,
    () => ({
      focus: () => innerRef.current?.shadowRoot?.querySelector('input')?.focus(),
    }),
    [],
  )

  const identifier = id || name
  const errorText = error?.message
  const shouldShowError = isTouched && !!errorText

  return (
    <WppFileUpload
      ref={mergeRefs([innerRef, ref])}
      {...rest}
      multiple={multiple}
      className={clsx(styles.root, className)}
      id={identifier}
      name={name}
      locales={fileLocales}
      value={value}
      message={shouldShowError ? errorText : message}
      messageType={shouldShowError ? 'error' : messageType}
      onWppChange={e => {
        onChange(e.detail.value)
        onWppChange?.(e)
      }}
      onWppBlur={e => {
        onBlur()
        onWppBlur?.(e)
      }}
    />
  )
})
