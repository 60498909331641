import { Provider as NiceModalProvider } from '@ebay/nice-modal-react'
import { lazy } from 'react'
import { Navigate, Route } from 'react-router-dom'

import { PermittedAccess } from 'components/common/PermittedAccess'
import { PermittedHubsAdminAccess } from 'components/common/PermittedHubsAdminAccess'
import { PermittedLayerAdminAccess } from 'components/common/PermittedLayerAdminAccess'
import { SuspenseLoader } from 'components/common/SuspenseLoader'
import {
  NotFoundError,
  RenderErrorBoundaryHeight,
  RenderErrorType,
  RouteRenderErrorBoundary,
} from 'components/renderError'
import {
  LOCAL_APP_BASE_URL,
  LOCAL_APP_DEFAULT_URL_CONFIG,
  LOCAL_LEGACY_APP_BASE_URL,
  LOCAL_LEGACY_APP_DEFAULT_URL_CONFIG,
} from 'constants/apps'
import { Permission } from 'constants/permission'
import { GENERIC_TENANT_DEV_ROUTE } from 'constants/routes'
import { PageContainer } from 'layout/pageContainer/PageContainer'
import { SecureLayout } from 'layout/SecureLayout'
import { FullscreenAppsNavigation } from 'pages/fullscreenAppsNavigation/FullscreenAppsNavigation'
import { NoAvailableHubs } from 'pages/hubs/noAvailableHubs/NoAvailableHubs'
import { HubsAdminPage } from 'pages/hubsAdmin/HubsAdminPage'
import { LayerAdminPage } from 'pages/layerAdmin/LayerAdminPage'
import { MiroAuthCallback } from 'pages/miroAuth/MiroAuthCallback'
import { WrikeAuthCallback } from 'pages/wrikeAuth/WrikeAuthCallback'
import { AppsProvider } from 'providers/apps/AppsProvider'
import { CurrentTenantDataProvider } from 'providers/currentTenantData/CurrentTenantDataProvider'
import { DevResetQueries } from 'providers/osQueryClient/DevResetQueries'
import { OsStateProvider } from 'providers/osState/OsStateProvider'
import { getHardCodedRoutes } from 'routes/specificTenantRoutes/hardcodedRoutes'
import { NavigateToHome } from 'routes/specificTenantRoutes/navigateToHome/NavigateToHome'
import { SecuredRoutesContainer } from 'routes/specificTenantRoutes/securedRoutesContainer/SecuredRoutesContainer'
import { TenantHostInfo } from 'types/tenants/tenant'
import { routesManager } from 'utils/routesManager'

const NetworkRoutes = lazy(() => import('pages/network/NetworkRoutes'))
const LinksRoutes = lazy(() => import('pages/links/LinksRoutes'))
const UserRoutes = lazy(() => import('pages/user/UserRoutes'))
const AdminRoutes = lazy(() => import('pages/admin/AdminRoutes'))
const HubsRoutes = lazy(() => import('pages/hubs/HubsRoutes'))
const HubsAdminRoutes = lazy(() => import('pages/hubsAdmin/HubsAdminRouter'))
const LayerAdminRoutes = lazy(() => import('pages/layerAdmin/LayerAdminRouter'))
const FilesRoutes = lazy(() => import('pages/files/FilesRoutes'))
const FilesFavoriteRoutes = lazy(() => import('pages/files/FilesFavoriteRoutes'))

export const getSpecificTenantRoutes = (hostInfo: TenantHostInfo) => (
  <Route
    element={
      <CurrentTenantDataProvider>
        <OsStateProvider>
          <AppsProvider>
            {/* Secured routes ModalProvider */}
            <NiceModalProvider>
              <SecureLayout />
            </NiceModalProvider>
          </AppsProvider>
        </OsStateProvider>
      </CurrentTenantDataProvider>
    }
  >
    <Route
      element={<SecuredRoutesContainer />}
      errorElement={
        /* Content-related issues boundary. */
        <RouteRenderErrorBoundary
          catchErrors={[
            RenderErrorType.DataIsNotAvailable,
            RenderErrorType.ForbiddenPage,
            RenderErrorType.NotFound,
            RenderErrorType.CriticalError,
            RenderErrorType.AppDisabled,
          ]}
          height={RenderErrorBoundaryHeight.FullPage}
        />
      }
    >
      <Route element={<PageContainer />}>
        <Route index element={<NavigateToHome />} />

        <Route path={routesManager.miroAuthCallback.routePath} element={<MiroAuthCallback />} />
        <Route path={routesManager.wrikeAuthCallback.routePath} element={<WrikeAuthCallback />} />

        {getHardCodedRoutes(hostInfo)}

        <Route path={routesManager.home.root.routePath}>
          <Route index element={<NoAvailableHubs />} />
          <Route path="*" element={<NotFoundError />} />
        </Route>
        <Route
          path={`${routesManager.hubs.root.routePath}/*`}
          element={
            <SuspenseLoader>
              <HubsRoutes />
            </SuspenseLoader>
          }
        />

        <Route
          path={`${routesManager.hubsAdmin.root.routePath}/*`}
          element={
            <PermittedHubsAdminAccess permission={Permission.OS_HUB_MANAGE}>
              <SuspenseLoader>
                <HubsAdminPage>
                  <HubsAdminRoutes />
                </HubsAdminPage>
              </SuspenseLoader>
            </PermittedHubsAdminAccess>
          }
        />

        <Route
          path={`${routesManager.layerAdmin.root.routePath}/*`}
          element={
            <PermittedLayerAdminAccess permission={Permission.OS_APP_INSTANCE_ENTITIES_MANAGE}>
              <SuspenseLoader>
                <LayerAdminPage>
                  <LayerAdminRoutes />
                </LayerAdminPage>
              </SuspenseLoader>
            </PermittedLayerAdminAccess>
          }
        />

        <Route
          path={`${routesManager.network.root.routePath}/*`}
          element={
            <PermittedAccess permission={Permission.NETWORK_APP_ACCESS}>
              <SuspenseLoader>
                <NetworkRoutes />
              </SuspenseLoader>
            </PermittedAccess>
          }
        />

        <Route
          path={`${routesManager.links.routePath}/*`}
          element={
            <PermittedAccess permission={Permission.OS_APPS_ACCESS}>
              <SuspenseLoader>
                <LinksRoutes />
              </SuspenseLoader>
            </PermittedAccess>
          }
        />

        <Route
          path={`${routesManager.files.favorites.routePath}/*`}
          element={
            <SuspenseLoader>
              <FilesFavoriteRoutes />
            </SuspenseLoader>
          }
        />

        <Route
          path={`${routesManager.files.hub.routePath}/*`}
          element={
            <SuspenseLoader>
              <FilesRoutes />
            </SuspenseLoader>
          }
        />

        <Route
          path={`${routesManager.user.root.routePath}/*`}
          element={
            <PermittedAccess permission={Permission.OS_USERDETAILS_APP_ACCESS}>
              <SuspenseLoader>
                <UserRoutes />
              </SuspenseLoader>
            </PermittedAccess>
          }
        />

        <Route
          path={`${routesManager.admin.root.routePath}/*`}
          element={
            <PermittedAccess permission={Permission.OS_ADMIN_SETTING_ACCESS}>
              <SuspenseLoader>
                <AdminRoutes />
              </SuspenseLoader>
            </PermittedAccess>
          }
        />
      </Route>

      <Route path={LOCAL_APP_BASE_URL} element={<Navigate replace to={LOCAL_APP_DEFAULT_URL_CONFIG} />} />
      <Route path={LOCAL_LEGACY_APP_BASE_URL} element={<Navigate replace to={LOCAL_LEGACY_APP_DEFAULT_URL_CONFIG} />} />

      {/**
       * Enable generic tenant workflow simulation. The generic tenant route will only be rendered when the loaded data
       * is related to specific tenant instead of generic. This happens during auth callback or when the user clicks a
       * browser's back button.
       */}
      {process.env.DEV && <Route path={`${GENERIC_TENANT_DEV_ROUTE}/*`} element={<DevResetQueries />} />}

      <Route path="*" element={<FullscreenAppsNavigation />} />
    </Route>
  </Route>
)
