import { WppButton, WppTypography } from '@platform-ui-kit/components-library-react'
import { FormProvider } from 'react-hook-form'
import { Trans, useTranslation } from 'react-i18next'

import { useRejectTenantAccessRequestsApi } from 'api/accessRequests/mutations/useRejectTenantAccessRequestsApi'
import { Flex } from 'components/common/flex/Flex'
import { FormTextareaInput } from 'components/form/formTextareaInput/FormTextareaInput'
import { Modal } from 'components/surface/modal/Modal'
import { ApiQueryKeys } from 'constants/apiQueryKeys'
import { useForm } from 'hooks/form/useForm'
import { useDateWithTimeFormat } from 'hooks/useDateWithTimeFormat'
import { useUsernameFormat } from 'hooks/useUsernameFormat'
import styles from 'pages/admin/requests/tenantAccessRequests/rejectTenantAccessRequestModal/RejectTenantAccessRequestModal.module.scss'
import {
  CHARACTERS_LIMIT,
  useValidationSchema,
} from 'pages/admin/requests/tenantAccessRequests/rejectTenantAccessRequestModal/utils'
import {
  handleReloadTenantAccessRequestsTables,
  isProcessedRequest,
} from 'pages/admin/requests/tenantAccessRequests/utils'
import { UsersInfoCard } from 'pages/admin/requests/usersInfoCard/UsersInfoCard'
import { queryClient } from 'providers/osQueryClient/utils'
import { useToast } from 'providers/toast/ToastProvider'
import { TenantAccessRequest } from 'types/requests/requests'
import { capitalize } from 'utils/common'
import { createNiceModal, NiceModalWrappedProps } from 'utils/createNiceModal'

interface Props extends NiceModalWrappedProps {
  request: TenantAccessRequest
  getAttachmentUrlByKey?: (key?: string) => string
  onSubmitted?: () => void
}

const RejectTenantAccessRequestModal = ({
  request,
  getAttachmentUrlByKey,
  isOpen,
  onSubmitted,
  onClose,
  onCloseComplete,
  id,
}: Props) => {
  const { enqueueToast } = useToast()
  const { t } = useTranslation()
  const { formatUsername } = useUsernameFormat()
  const { formatDate } = useDateWithTimeFormat()

  const form = useForm({
    validationSchema: useValidationSchema(),
    defaultValues: { rejectReason: '' },
  })

  const { mutateAsync: handleRejectTenantAccessRequests } = useRejectTenantAccessRequestsApi()

  const { handleSubmit, formState } = form
  const { isValid, isSubmitting } = formState

  const { firstname, lastname } = request.userData || {}
  const username = formatUsername(firstname, lastname)

  const onSubmit = handleSubmit(async ({ rejectReason }) => {
    try {
      await handleRejectTenantAccessRequests({
        ids: [request.id],
        rejectReason,
      })

      queryClient.invalidateQueries({ queryKey: [ApiQueryKeys.ACCESS_REQUEST, { id: request.id }] })
      handleReloadTenantAccessRequestsTables()

      enqueueToast({
        type: 'success',
        message: capitalize(t('os.requests.tenant_access_requests.toasts.reject')),
      })

      onClose()
    } catch (error) {
      if (isProcessedRequest(error)) {
        enqueueToast({
          message: capitalize(t('os.common.errors.processed_request')),
          type: 'error',
        })
      } else {
        enqueueToast({
          message: capitalize(t('os.common.errors.general')),
          type: 'error',
        })
      }
    } finally {
      onSubmitted?.()
      onClose()
    }
  })

  const title = capitalize([t('os.requests.actions.reject'), t('os.requests.request_types.os_access')].join(' '))

  return (
    <FormProvider {...form}>
      <Modal
        open={isOpen}
        onWppModalClose={onClose}
        onWppModalCloseComplete={onCloseComplete}
        data-testid={id}
        size="m"
        formConfig={{ onSubmit }}
      >
        <WppTypography type="xl-heading" slot="header">
          {title}
        </WppTypography>
        <Flex direction="column" slot="body" gap={16}>
          <WppTypography type="s-body">
            <Trans
              i18nKey="os.requests.actions_modal.description"
              values={{
                action: t('os.requests.actions.reject'),
                requestType: t('os.requests.request_types.os_access'),
                username,
              }}
              components={[
                <b key="0">action</b>,
                <b key="1">requestType</b>,
                <b className={styles.bold} key="2">
                  username
                </b>,
              ]}
            />
          </WppTypography>

          {!!getAttachmentUrlByKey && (
            <UsersInfoCard
              request={request}
              getAttachmentUrlByKey={getAttachmentUrlByKey}
              date={formatDate(request.createdAt)}
            />
          )}

          <FormTextareaInput
            required
            labelConfig={{ text: t('os.requests.actions_modal.fields.reject_reason.label') }}
            charactersLimit={CHARACTERS_LIMIT}
            warningThreshold={CHARACTERS_LIMIT}
            name="rejectReason"
            placeholder={t('os.requests.actions_modal.fields.reject_reason.placeholder')}
          />
        </Flex>

        <Flex slot="actions" gap={12} justify="end">
          <WppButton variant="secondary" onClick={onClose} data-testid="cancel">
            {t('os.common.cancel')}
          </WppButton>
          <WppButton variant="primary" type="submit" loading={isSubmitting} disabled={!isValid} data-testid="apply">
            {capitalize(t('os.requests.actions.reject'))}
          </WppButton>
        </Flex>
      </Modal>
    </FormProvider>
  )
}

export const { showModal: showRejectTenantAccessRequestModal } = createNiceModal(
  RejectTenantAccessRequestModal,
  'reject-tenant-access-request-modal',
)
