import { useMePermissionByAccountsAndPermissionsApi } from 'api/alphaZulu/queries/useMePermissionByAccountsAndPermissionsApi'
import { useHierarchyTreeApi } from 'api/navigation/queries/useHierarchyTreeApi'
import { Permission } from 'constants/permission'
import { useWorkspaceAccountIds } from 'hooks/useWorkspaceAccountIds'
import { useCurrentTenantData } from 'providers/currentTenantData/CurrentTenantDataContext'

export const useUserCanManageApps = () => {
  const { currentTenant } = useCurrentTenantData()

  const { data: hierarchyTree } = useHierarchyTreeApi({
    params: {
      tenantId: currentTenant.id,
    },
  })
  const accountIds = useWorkspaceAccountIds(hierarchyTree)
  const { data: hierarchyManagePermissions, isFetched } = useMePermissionByAccountsAndPermissionsApi({
    params: {
      account_ids: accountIds,
      perms: [Permission.OS_APP_INSTANCE_ENTITIES_MANAGE],
      perm_prefixes: [Permission.OS_PREFIX],
    },
    enabled: !!hierarchyTree,
  })

  return { isUserCanManage: !isFetched || !!hierarchyManagePermissions?.length }
}
