export enum TableKey {
  /* Network */

  // Contacts
  PRIMARY_CONTACTS = 'PRIMARY_CONTACTS',
  ALL_CONTACTS = 'ALL_CONTACTS',

  /* Admin */

  // Members
  MEMBERS = 'MEMBERS',
  VIEW_MEMBER_ROLES = 'VIEW_MEMBER_ROLES',
  EDIT_MEMBER_ROLES = 'EDIT_MEMBER_ROLES',
  VIEW_MEMBER_GROUPS = 'VIEW_MEMBER_GROUPS',

  // Groups
  GROUPS = 'GROUPS',
  VIEW_GROUP_ROLES = 'VIEW_GROUP_ROLES',
  VIEW_GROUP_MEMBERS = 'VIEW_GROUP_MEMBERS',

  // Roles
  ROLES = 'ROLES',
  VIEW_ROLE_MEMBERS = 'VIEW_ROLE_MEMBERS',
  VIEW_ROLE_GROUPS = 'VIEW_ROLE_GROUPS',
  GROUP_ROLES = 'GROUP_ROLES',
  TENANTS = 'TENANTS',

  // Master Data
  MASTER_DATA_AGENCIES = 'MASTER_DATA_AGENCIES',
  MASTER_DATA_BRANDS = 'MASTER_DATA_BRANDS',
  MASTER_DATA_CLIENTS = 'MASTER_DATA_CLIENTS',
  MASTER_DATA_INDUSTRIES = 'MASTER_DATA_INDUSTRIES',
  MASTER_DATA_MARKETS = 'MASTER_DATA_MARKETS',
  MASTER_DATA_REGIONS = 'MASTER_DATA_REGIONS',

  // Apps
  LEAN_APPS = 'LEAN_APPS',
  NATIVE_APPS = 'NATIVE_APPS',

  // Notifications
  NOTIFICATIONS = 'NOTIFICATIONS',

  // Hubs
  HUBS = 'HUBS',
  VIEW_HUB_MEMBERS = 'VIEW_HUB_MEMBERS',
  EDIT_HUB_MEMBERS = 'EDIT_HUB_MEMBERS',
  VIEW_MEMBER_HUBS = 'VIEW_MEMBER_HUBS',

  // Tenant Access Requests
  TENANT_ACCESS_PENDING_REQUESTS = 'TENANT_ACCESS_PENDING_REQUESTS',
  TENANT_ACCESS_COMPLETED_REQUESTS = 'TENANT_ACCESS_COMPLETED_REQUESTS',

  // Hierarchy Access Requests
  HIERARCHY_ACCESS_PENDING_REQUESTS = 'HIERARCHY_ACCESS_PENDING_REQUESTS',
  HIERARCHY_ACCESS_COMPLETED_REQUESTS = 'HIERARCHY_ACCESS_COMPLETED_REQUESTS',

  // Hub Access Requests
  HUB_ACCESS_PENDING_REQUESTS = 'HUB_ACCESS_PENDING_REQUESTS',
  HUB_ACCESS_COMPLETED_REQUESTS = 'HUB_ACCESS_COMPLETED_REQUESTS',

  // Sign-Up Requests
  SIGN_UP_PENDING_REQUESTS = 'SIGN_UP_PENDING_REQUESTS',
  SIGN_UP_COMPLETED_REQUESTS = 'SIGN_UP_COMPLETED_REQUESTS',

  // Files
  FILES = 'FILES',
}

export enum TableDefaults {
  LoaderStaleTime = 60 * 1000,
  CacheBlockSize = 50,
}
