import { WppListItem, WppTag, WppActionButton, WppIconPin } from '@platform-ui-kit/components-library-react'
import clsx from 'clsx'
import { useTranslation } from 'react-i18next'

import { Avatar } from 'components/common/avatar/Avatar'
import { Flex } from 'components/common/flex/Flex'
import { SvgPinFilledIcon } from 'components/svg/icons/SvgPinFilledIcon'
import styles from 'layout/header/tenantSwitcher/TenantSwitcher.module.scss'
import { useOsRoot } from 'providers/osRoot/OsRootContext'
import { TenantShort } from 'types/tenants/tenant'

interface TenantListItemProps {
  tenant: TenantShort
  checked?: boolean
  isTenantPinned: boolean
  isCurrentTenant: boolean
  onClickHandler: (tenant: TenantShort) => void
  onWppChangeListItem?: () => void
}

export const TenantListItem = ({
  tenant,
  isCurrentTenant,
  isTenantPinned,
  checked = false,
  onClickHandler,
  onWppChangeListItem,
}: TenantListItemProps) => {
  const { t } = useTranslation()

  const { defaultTenant } = useOsRoot()

  return (
    <WppListItem
      checked={checked}
      onWppChangeListItem={onWppChangeListItem}
      className={clsx(styles.tenantListItem, { [styles.currentTenant]: checked })}
    >
      <Avatar
        slot="left"
        name={tenant.name}
        src={tenant.logoThumbnail?.url || defaultTenant.logoThumbnail?.url || undefined}
      />
      <span slot="label">{tenant.name}</span>

      <Flex slot="right" align="center">
        <WppActionButton
          onClick={e => {
            e.preventDefault()
            onClickHandler(tenant)
          }}
        >
          {isTenantPinned ? (
            <SvgPinFilledIcon />
          ) : (
            <>
              <WppIconPin className={styles.pinIcon} data-testid="tenant-pin-icon" />
              {isCurrentTenant && <WppTag label={t('os.common.home')} variant="neutral" className={styles.isHomeTag} />}
            </>
          )}
        </WppActionButton>
      </Flex>
    </WppListItem>
  )
}
