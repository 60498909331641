import { WppActionButton, WppButton, WppIconChevron, WppTypography } from '@platform-ui-kit/components-library-react'
import { useMemo } from 'react'
import { FormProvider } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

import { useImportCalendarWidgetEvents } from 'api/calendars/mutations/useImportCalendarWidgetEvents'
import { Flex } from 'components/common/flex/Flex'
import { FormFileUpload } from 'components/form/formFileUpload/FormFileUpload'
import { SideModal } from 'components/surface/sideModal/SideModal'
import { ApiQueryKeys } from 'constants/apiQueryKeys'
import { useForm } from 'hooks/form/useForm'
import styles from 'pages/home/systemWidgets/calendarWidget/importEventsSideModal/ImportEventsSideModal.module.scss'
import {
  getDefaultValues,
  ImportEventsModalFormValues,
} from 'pages/home/systemWidgets/calendarWidget/importEventsSideModal/utils'
import { useHubId } from 'pages/hubs/utils'
import { queryClient } from 'providers/osQueryClient/utils'
import { useToast } from 'providers/toast/ToastProvider'
import { NiceModalWrappedProps, createNiceModal } from 'utils/createNiceModal'
import { isFile } from 'utils/files'

export interface ImportEventsSideModalProps extends NiceModalWrappedProps {
  calendarId: string
}

export const ImportEventsSideModal = ({
  id,
  isOpen,
  onClose,
  onCloseComplete,
  calendarId,
}: ImportEventsSideModalProps) => {
  const hubId = useHubId()
  const { t } = useTranslation()
  const { enqueueToast } = useToast()
  const { mutateAsync: handleImportCalendarWidgetEvents } = useImportCalendarWidgetEvents()

  const form = useForm({
    defaultValues: useMemo(() => getDefaultValues(), []),
  })

  const {
    handleSubmit,
    formState: { isSubmitting, isDirty },
  } = form

  const onSubmit = handleSubmit(async ({ eventsFiles }: ImportEventsModalFormValues) => {
    try {
      const eventsFile = isFile(eventsFiles[0]) ? eventsFiles[0] : null

      if (!eventsFile) {
        return
      }

      const {
        data: { eventsUploaded },
      } = await handleImportCalendarWidgetEvents({
        hubId,
        calendarId,
        file: eventsFile,
      })

      await Promise.all([
        queryClient.invalidateQueries({ queryKey: [ApiQueryKeys.CALENDAR_WIDGET_EVENTS] }),
        queryClient.invalidateQueries({ queryKey: [ApiQueryKeys.CALENDAR_WIDGET_ALL_EVENTS] }),
      ])

      enqueueToast({
        message: t('os.home.calendar_widget.import_events.toasts.add_success', {
          count: eventsUploaded,
        }),
        type: 'success',
      })
    } catch {
      enqueueToast({
        message: t('os.common.errors.general'),
        type: 'error',
      })
    } finally {
      onClose()
    }
  })

  return (
    <FormProvider {...form}>
      <SideModal
        disableOutsideClick
        size="m"
        formConfig={{ onSubmit }}
        open={isOpen}
        onWppSideModalClose={onClose}
        onWppSideModalCloseComplete={onCloseComplete}
        data-testid={id}
      >
        <Flex slot="header" align="center">
          <WppActionButton onClick={onClose}>
            <WppIconChevron slot="icon-start" direction="left" className={styles.backIcon} />
          </WppActionButton>
          <WppTypography type="2xl-heading">{t('os.home.calendar_widget.import_events.header')}</WppTypography>
        </Flex>

        <Flex slot="body" direction="column" gap={24}>
          <FormFileUpload
            name="eventsFiles"
            size={10}
            acceptConfig={{
              'text/calendar': ['.ics'],
            }}
            maxLabelLength={45}
            data-testid="events-files"
          />
        </Flex>

        <Flex slot="actions" justify="end" gap={12}>
          <WppButton variant="secondary" size="m" onClick={onClose}>
            {t('os.common.cancel')}
          </WppButton>
          <WppButton variant="primary" size="m" type="submit" disabled={!isDirty} loading={isSubmitting}>
            {t('os.common.import')}
          </WppButton>
        </Flex>
      </SideModal>
    </FormProvider>
  )
}

export const { showModal: showImportEventsSideModal } = createNiceModal(
  ImportEventsSideModal,
  'calendar-widget-import-events-modal',
)
