import { WppIconExternalLink, WppIconWarning, WppTooltip } from '@platform-ui-kit/components-library-react'
import { AppInstanceShort, NoCodeAppType } from '@wpp-open/core'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'

import { Avatar } from 'components/common/avatar/Avatar'
import { useNavigationMenuController } from 'components/navigationMenu/navigationMenuContent/NavigationMenuControllerContext'
import { TreeListItem } from 'components/treeList/treeListItem/TreeListItem'
import { LaunchLocations } from 'constants/analytics'
import { useCurrentTenantData } from 'providers/currentTenantData/CurrentTenantDataContext'
import { useOsState } from 'providers/osState/OsStateProvider'
import { useOtherTenantsAndUserData } from 'providers/otherTenantsAndUserData/OtherTenantsAndUserDataContext'
import { MiroConfigStatus } from 'types/miro'
import { SourceLocations } from 'types/osState/appLaunchData'
import { trackAppOpenAnalytics } from 'utils/analytics'
import { mapAppInstanceToApp } from 'utils/appInstances'
import { routesManager } from 'utils/routesManager'
import { isWorkspaceIdValid, resolveHierarchyPath } from 'utils/workspace'

interface Props {
  appInstance: AppInstanceShort
  logoUrl: string
}

export const AppNavigationMenuItem = ({ appInstance, logoUrl }: Props) => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const { userDetails } = useOtherTenantsAndUserData()
  const { closeNavigation, appData, miroConfigStatus, setAppLaunchData } = useOsState()
  const { navigationTreeWithHiddenLevel } = useCurrentTenantData()
  const { selectedNavigationNodeId } = useNavigationMenuController()

  const app = mapAppInstanceToApp(appInstance)

  // tracking used only for external link apps
  const handleTrackAnalytics = () => {
    const isValidHierarchyId = isWorkspaceIdValid({
      workspaceAzId: selectedNavigationNodeId,
      navigationTree: navigationTreeWithHiddenLevel,
    })

    const selectedHierarchyWithHiddenLevel = resolveHierarchyPath({
      validHierarchyId: isValidHierarchyId ? selectedNavigationNodeId : null,
      navigationTree: navigationTreeWithHiddenLevel,
    })

    trackAppOpenAnalytics({
      source: SourceLocations.Core,
      userDetails,
      launchedFrom: LaunchLocations.HamburgerMenu,
      productName: appInstance.devhubMetadata.name,
      productType: app.type,
      productOwnerEmail: appInstance.devhubMetadata.ownerEmail,
      productCommercialModel: appInstance.devhubMetadata.commercialModel?.model,
      productCategory: appInstance.devhubMetadata.applicationCategory?.category,
      productSubcategory: appInstance.devhubMetadata.applicationCategory?.subcategory,
      appId: appInstance.devhubMetadata.id,
      appInstanceId: app.stableId,
      appInstanceName: app.name,
      navigationTreeWithHiddenLevel,
      activeHierarchyWithHiddenLevel: selectedHierarchyWithHiddenLevel,
    })
  }

  if (app.type === NoCodeAppType.ExternalLink) {
    return (
      <TreeListItem
        linkConfig={{
          target: '_blank',
          rel: 'noreferrer',
          href: app.url,
        }}
        onClick={handleTrackAnalytics}
      >
        <Avatar size="xs" slot="left" name={app.name} src={logoUrl} />
        <span slot="label">{app.name}</span>
        <WppIconExternalLink slot="right" />
      </TreeListItem>
    )
  }

  const isActive = !!appData.app && appData.app.stableId === app.stableId
  const href = routesManager.app({
    id: app.stableId,
    osRoute: app.osRoute,
  })
  const isMiroWarningVisible =
    miroConfigStatus?.status !== MiroConfigStatus.Valid && app.type === NoCodeAppType.MiroBoard

  return (
    <TreeListItem
      checked={isActive}
      linkConfig={{
        href,
        onClick: e => e.preventDefault(),
      }}
      onClick={e => {
        if (!e.currentTarget.checked) {
          setAppLaunchData({
            appInstanceId: appInstance.id,
            source: SourceLocations.Core,
            launchedFrom: LaunchLocations.HamburgerMenu,
          })
          navigate(href)
          closeNavigation()
        }
      }}
    >
      <Avatar size="xs" slot="left" name={app.name} src={logoUrl} />
      <span slot="label">{app.name}</span>

      {isMiroWarningVisible && (
        <WppTooltip slot="right" text={t('os.navigation_menu.miro_app_warning_text')}>
          <WppIconWarning />
        </WppTooltip>
      )}
    </TreeListItem>
  )
}
