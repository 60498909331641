import { Flex } from 'components/common/flex/Flex'
import styles from 'layout/header/breadcrumbs/Breadcrumbs.module.scss'
import { HierarchyBreadcrumbs } from 'layout/header/breadcrumbs/hierarchy/HierarchyBreadcrumbs'
import { ProjectFluidBreadcrumbs } from 'layout/header/breadcrumbs/project/projectFluid/ProjectFluidBreadcrumbs'
import { ProjectLinearBreadcrumbs } from 'layout/header/breadcrumbs/project/projectLinear/ProjectLinearBreadcrumbs'
import { HubSwitcher } from 'layout/header/hubSwitcher/HubSwitcher'
import { BreadcrumbsData, BreadcrumbsType } from 'types/osState/breadcrumbs'

interface Props {
  data: BreadcrumbsData
}

export const Breadcrumbs = ({ data }: Props) => {
  if (data.type === BreadcrumbsType.ProjectLinear) {
    return (
      <Flex>
        <div className={styles.hubSwitcherWrapper}>
          <HubSwitcher useInBreadcrumbs />
        </div>
        <ProjectLinearBreadcrumbs items={data.items} />
      </Flex>
    )
  }

  if (data.type === BreadcrumbsType.ProjectFluid) {
    return (
      <Flex>
        <div className={styles.hubSwitcherWrapper}>
          <HubSwitcher useInBreadcrumbs />
        </div>
        <ProjectFluidBreadcrumbs items={data.items} />
      </Flex>
    )
  }

  return (
    <Flex>
      <div className={styles.hubSwitcherWrapper}>
        <HubSwitcher useInBreadcrumbs />
      </div>
      <HierarchyBreadcrumbs items={data.items} />
    </Flex>
  )
}
