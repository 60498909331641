import { WppButton, WppTypography } from '@platform-ui-kit/components-library-react'
import { FormProvider } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

import { useCreateHubAccessRequestApi } from 'api/hubs/mutations/useCreateHubAccessRequestApi'
import { isConflictError } from 'api/utils'
import { Avatar } from 'components/common/avatar/Avatar'
import { Flex } from 'components/common/flex/Flex'
import { FormSelect } from 'components/form/formSelect/FormSelect'
import { FormTextareaInput } from 'components/form/formTextareaInput/FormTextareaInput'
import { showHubAlreadyRequestedModal } from 'components/modal/requestHubModal/HubAlreadyRequestedModal'
import { MAX_CHARACTERS, useValidationScheme } from 'components/modal/requestHubModal/utils'
import { Modal } from 'components/surface/modal/Modal'
import { ApiQueryKeys } from 'constants/apiQueryKeys'
import { useForm } from 'hooks/form/useForm'
import { queryClient } from 'providers/osQueryClient/utils'
import { useToast } from 'providers/toast/ToastProvider'
import { Hub } from 'types/hubs/hubs'
import { createNiceModal, NiceModalWrappedProps } from 'utils/createNiceModal'

interface Props extends NiceModalWrappedProps {
  title: string
  hubId?: string
  hubs?: Hub[]
  showHubAlreadyRequestedModal?: () => void
}

export const RequestHubModal = ({
  isOpen,
  title,
  hubId,
  hubs,
  onClose,
  onCloseComplete,
  id,
  showHubAlreadyRequestedModal: showHubAlreadyRequestedModalFromProps,
}: Props) => {
  const { t } = useTranslation()
  const { enqueueToast } = useToast()

  const { mutateAsync: handleHubAccessRequest, isLoading, reset: resetRequest } = useCreateHubAccessRequestApi()

  const form = useForm({
    defaultValues: { hubId: hubId ?? '', requestReason: '' },
    validationSchema: useValidationScheme(),
  })

  const {
    resetField,
    handleSubmit,
    formState: { isValid },
  } = form

  const onSubmit = handleSubmit(async values => {
    try {
      await handleHubAccessRequest({
        hubId: values.hubId,
        requestReason: values.requestReason,
      })

      enqueueToast({
        message: t('os.common.toasts.request_access'),
        type: 'success',
      })
    } catch (e) {
      if (isConflictError(e)) {
        const requestedHubName = hubs?.find(({ id }) => id === values.hubId)?.name ?? ''
        showHubAlreadyRequestedModalFromProps
          ? showHubAlreadyRequestedModalFromProps()
          : showHubAlreadyRequestedModal({ hubName: requestedHubName })
      } else {
        enqueueToast({
          message: t('os.common.errors.error'),
          type: 'error',
        })
      }
    } finally {
      queryClient.invalidateQueries({ queryKey: [ApiQueryKeys.REQUESTABLE_HUBS] })
      resetField('requestReason')
      resetRequest()
      onClose()
    }
  })

  return (
    <FormProvider {...form}>
      <Modal
        formConfig={{ onSubmit }}
        open={isOpen}
        data-testid={id}
        onWppModalClose={onClose}
        onWppModalCloseComplete={onCloseComplete}
      >
        <WppTypography slot="header" type="2xl-heading">
          {title}
        </WppTypography>

        <Flex slot="body" direction="column" gap={24}>
          {hubs && (
            <FormSelect
              withSearch={hubs.length >= 5}
              required
              name="hubId"
              placeholder={t('os.request_access_modal.hub.hub_field.placeholder')}
              labelConfig={{ text: t('os.request_access_modal.hub.hub_field.label') }}
              options={hubs}
              getOptionValue={({ id }) => id}
              renderOptionContent={({ name, logoThumbnail, logoOriginal }) => (
                <>
                  <Avatar slot="left" name={name} src={logoThumbnail?.url || logoOriginal?.url || undefined} />
                  <span slot="label">{name}</span>
                </>
              )}
              data-testid="access-request-hub-selector"
            />
          )}
          <FormTextareaInput
            required
            labelConfig={{ text: t('os.request_access_modal.hub.access_reason_field.label') }}
            name="requestReason"
            placeholder={t('os.request_access_modal.hub.access_reason_field.placeholder')}
            charactersLimit={MAX_CHARACTERS}
            warningThreshold={MAX_CHARACTERS}
            data-testid="access-request-reason-input"
          />
        </Flex>

        <Flex slot="actions" gap={12} justify="end">
          <WppButton variant="secondary" size="s" onClick={onClose} data-testid="cancel">
            {t('os.common.cancel')}
          </WppButton>
          <WppButton
            type="submit"
            variant="primary"
            size="s"
            disabled={!isValid}
            data-testid="apply"
            loading={isLoading}
          >
            {t('os.request_access_modal.workspace.send_request')}
          </WppButton>
        </Flex>
      </Modal>
    </FormProvider>
  )
}

export const { showModal: showRequestHubModal } = createNiceModal(RequestHubModal, 'request-hub-modal')
