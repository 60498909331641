import { WppActionButton, WppTag } from '@platform-ui-kit/components-library-react'
import { useTranslation } from 'react-i18next'

import { Flex } from 'components/common/flex/Flex'
import { hideNotificationItemSideModal } from 'components/notificationItemSideModal/NotificationItemSideModal'
import {
  isCompleted,
  useStatusTagOptions,
} from 'components/notificationItemSideModal/notificationSideModalContent/utils'
import { hideNotificationsSideModal } from 'components/notificationsSideModal/NotificationsSideModal'
import { RequestsType } from 'constants/requests'
import { useStableCallback } from 'hooks/useStableCallback'
import { AccessRequestStatus } from 'pages/admin/requests/utils'

interface Props {
  type: RequestsType
  status?: AccessRequestStatus
  handleModalOpen: () => void
  isRequestNotFound: boolean
}

export const RequestStatusDetails = ({ status, type, handleModalOpen, isRequestNotFound }: Props) => {
  const { variant, label } = useStatusTagOptions(type, status)
  const { t } = useTranslation()

  const handleRequestDetailClick = useStableCallback(() => {
    handleModalOpen()
    hideNotificationsSideModal()
    hideNotificationItemSideModal()
  })

  return (
    <Flex gap={4} justify="between" align="center">
      <Flex>
        {isCompleted(status) && <WppTag variant={variant} data-testid="notification-item-status" label={label} />}
      </Flex>
      <Flex justify="end">
        <WppActionButton
          onClick={handleRequestDetailClick}
          data-testid="notification-item-all-requests"
          disabled={isRequestNotFound}
        >
          {t('os.requests.view_request')}
        </WppActionButton>
      </Flex>
    </Flex>
  )
}
