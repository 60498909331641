import { FilesFormat } from 'pages/files/constants'
import { AssignmentType } from 'types/files/files'

export type FileType = keyof typeof FilesFormat

export interface FilesListLoaderParams {
  search: string
  assignmentId?: string
  assignmentType?: AssignmentType
  parentId?: string
  isFavorite?: boolean
  ownerFilter?: OwnerFilterValue
  category: FileCategory
}

export enum OwnerFilterValue {
  MY = 'my',
  ALL = 'all',
}

export enum FileCategory {
  ALL = 'ALL',
  AUDIO = 'AUDIO',
  COMPRESSED = 'COMPRESSED',
  DATA = 'DATA',
  IMAGE = 'IMAGE',
  PRESENTATION = 'PRESENTATION',
  SPREADSHEET = 'SPREADSHEET',
  VIDEO = 'VIDEO',
  WORD_PROCESSOR = 'WORD_PROCESSOR',
  OTHER = 'OTHER',
}
