import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import * as zod from 'zod'

const MAX_CHARACTERS = 128

export const useValidationScheme = (extension: string) => {
  const { t } = useTranslation()
  const maxNameLength = MAX_CHARACTERS - extension.length - 1
  return useMemo(
    () =>
      zod.object({
        name: zod
          .string()
          .min(
            1,
            t('os.common.errors.min_max_length', {
              entity: t('os.files.file_details_modal.file_name'),
              min: 1,
              max: maxNameLength,
            }),
          )
          .max(
            MAX_CHARACTERS,
            t('os.common.errors.min_max_length', {
              entity: t('os.files.file_details_modal.file_name'),
              min: 1,
              max: maxNameLength,
            }),
          ),
      }),
    [maxNameLength, t],
  )
}
