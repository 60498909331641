export enum LaunchLocations {
  NavigationMenu = 'Navigation Menu',
  Breadcrumbs = 'Breadcrumbs',
  ProjectWorkflow = 'Project Workflow',
  Favorites = 'Favorites',
  HamburgerMenu = 'Hamburger Menu',
  LoginPage = 'Login Page',
  ListTenants = 'List Tenants',
  ErrorPage = 'Error Page',
}

export const OsModuleNames: { [index: string]: string } = {
  community: 'Community',
  knowledge_base: 'Knowledge Base',
  support: 'Support',
  os_status: 'OS Status',
  marketplace: 'Marketplace',
  intercom: 'Intercom',
  news: 'News',
  network: 'Network',
  devhub: 'DevHub',
}

export const ANALYTICS_EVENTS = {
  TENANT_LOGIN_PAGE: {
    PAGE: {
      LOGIN: 'page_login',
    },
    ACTIONS: {
      FORGOT_PASSWORD: 'action_login_forgot_password',
      SIGN_UP: 'action_login_sign_up',
      MAGIC_LINK: 'action_login_with_magic_link',
      EMAIL: 'action_login_with_email',
      OKTA: 'action_login_with_okta',
      GROUP_M: 'action_login_with_groupm',
      GET_SUPPORT: 'action_login_get_support',
      CANT_LOGIN: "action_login_can't_login",
    },
  },
  HOME_PAGE: {
    PAGE: {
      OPEN: 'page_home',
    },
    ACTIONS: {
      HOME_ICON: 'action_home_home_icon',
      HOME_PROJECTS_WIDGET_VIEW_ALL: 'action_home_projects_widget_view_all',
      HOME_PROJECTS_WIDGET_ADD_PROJECT: 'action_home_projects_widget_add_project',
      HOME_PROJECTS_WIDGET_ITEM_SELECT: 'action_home_projects_widget_{itemName}_item_select',
      HOME_FAVORITE_LINKS_WIDGET_ADD_LINK: 'action_home_favorite_links_widget_add_link',
      HOME_FAVORITE_LINKS_WIDGET_VIEW_ALL: 'action_home_favorite_links_widget_view_all',
      HOME_FAVORITE_LINKS_WIDGET_ITEM_SELECT: 'action_home_favorite_links_widget_{itemName}_item_select',
      HOME_CLIENTS_WIDGET_VIEW_ALL: 'action_home_clients_widget_view_all',
      HOME_CLIENTS_WIDGET_ITEM_SELECT: 'action_home_clients_widget_{itemName}_item_select',
      HOME_PROJECT_TEMPLATES_WIDGET_ADD_TEMPLATE: 'action_home_project_templates_widget_add_template',
      HOME_PROJECT_TEMPLATES_WIDGET_VIEW_ALL: 'action_home_project_templates_widget_view_all',
      HOME_PROJECT_TEMPLATES_WIDGET_ITEM_SELECT: 'action_home_project_templates_{itemName}_item_select',
      HOME_NEWS_WIDGET_ADD_SOURCE: 'action_home_news_widget_add_source',
      HOME_NEWS_WIDGET_ITEM_CLICK: 'action_home_news_widget_item_click',
      HOME_EXPERT_HUB_WIDGET_CLICK: 'action_home_expert_hub_widget_click',
      HOME_NEWS_WIDGET_VIEW_ALL: 'action_home_news_widget_view_all',
      HOME_EDIT_HOMEPAGE: 'action_home_edit_homepage',
      HOME_EDIT_HOMEPAGE_ADD_WIDGET: 'action_home_edit_homepage_add_widget',
      HOME_EDIT_HOMEPAGE_SAVE: 'action_home_edit_homepage_save',
      HOME_EDIT_HOMEPAGE_CANCEL: 'action_home_edit_homepage_cancel',
      HOME_CREATE_CUSTOM_WIDGET: 'action_home_create_custom_widget',
      HOME_CUSTOM_WIDGET_CLICK: 'action_home_custom_widget_click',
      HOME_NAV_MENU_ITEM_CLICK: 'action_home_nav_menu_{itemName}_item_select',
      HOME_PROJECT_TRACKING_WIDGET_OPEN_PROJECT: 'action_home_project_tracking_widget_open_project',
    },
  },
  HUB_PAGE: {
    PAGE: {
      OPEN: 'page_hub_home',
    },
    ACTIONS: {
      HOME_ICON: 'action_hub_home_home_icon',
      HOME_PROJECTS_WIDGET_VIEW_ALL: 'action_hub_home_projects_widget_view_all',
      HOME_PROJECTS_WIDGET_ADD_PROJECT: 'action_hub_home_projects_widget_add_project',
      HOME_PROJECTS_WIDGET_ITEM_SELECT: 'action_hub_home_projects_widget_{itemName}_item_select',
      HOME_FAVORITE_LINKS_WIDGET_ADD_LINK: 'action_hub_home_favorite_links_widget_add_link',
      HOME_FAVORITE_LINKS_WIDGET_VIEW_ALL: 'action_hub_home_favorite_links_widget_view_all',
      HOME_FAVORITE_LINKS_WIDGET_ITEM_SELECT: 'action_hub_home_favorite_links_widget_{itemName}_item_select',
      HOME_CLIENTS_WIDGET_VIEW_ALL: 'action_hub_home_clients_widget_view_all',
      HOME_CLIENTS_WIDGET_ITEM_SELECT: 'action_hub_home_clients_widget_{itemName}_item_select',
      HOME_PROJECT_TEMPLATES_WIDGET_ADD_TEMPLATE: 'action_hub_home_project_templates_widget_add_template',
      HOME_PROJECT_TEMPLATES_WIDGET_VIEW_ALL: 'action_hub_home_project_templates_widget_view_all',
      HOME_PROJECT_TEMPLATES_WIDGET_ITEM_SELECT: 'action_hub_home_project_templates_{itemName}_item_select',
      HOME_NEWS_WIDGET_ADD_SOURCE: 'action_hub_home_home_news_widget_add_source',
      HOME_NEWS_WIDGET_ITEM_CLICK: 'action_hub_home_news_widget_item_click',
      HOME_EXPERT_HUB_WIDGET_CLICK: 'action_hub_home_expert_hub_widget_click',
      HOME_NEWS_WIDGET_VIEW_ALL: 'action_hub_home_news_widget_view_all',
      HOME_EDIT_HOMEPAGE: 'action_hub_home_edit_homepage',
      HOME_EDIT_HOMEPAGE_ADD_WIDGET: 'action_hub_home_edit_homepage_add_widget',
      HOME_EDIT_HOMEPAGE_SAVE: 'action_hub_home_edit_homepage_save',
      HOME_EDIT_HOMEPAGE_CANCEL: 'action_hub_home_edit_homepage_cancel',
      HOME_CREATE_CUSTOM_WIDGET: 'action_hub_home_create_custom_widget',
      HOME_CUSTOM_WIDGET_CLICK: 'action_hub_home_custom_widget_click',
      HOME_NAV_MENU_ITEM_CLICK: 'action_hub_home_nav_menu_{itemName}_item_select',
      HOME_PROJECT_TRACKING_WIDGET_OPEN_PROJECT: 'action_hub_home_project_tracking_widget_open_project',
    },
  },
  HAMBURGER_MENU: {
    ACTIONS: {
      ACTION_HUMBURGER_MENU: 'action_humburger_menu',
      ACTION_HUMBURGER_MENU_ITEM: 'action_humburger_menu_{itemName}',
    },
  },
  LIST_OF_TENANTS: {
    ACTIONS: {
      LIST: 'action_list_of_tenants',
    },
    PAGE: {
      OPEN_TENANT: 'page_{itemName}',
    },
  },
  LIST_OF_HUBS: {
    ACTIONS: {
      LIST: 'action_list_of_hubs',
    },
    PAGE: {
      OPEN_HUB: 'page_{itemName}',
    },
  },
  HELP: {
    ACTIONS: {
      HELP: 'action_help',
      HELP_KNOWLEDGE_BASE: 'action_help_knowledge_base',
      HELP_GET_SUPPORT: 'action_help_get_support',
    },
  },
  // TODO: maybe remove since the news APP handle these events already
  NEWS: {
    PAGE: {
      OPEN: 'page_news',
    },
    ACTIONS: {
      NEWS_SETTINGS: 'action_news_settings',
      NEWS_SETTINGS_SYNC_NEWS: 'action_news_settings_sync_news',
      NEWS_SETTINGS_ADD_SOURCE: 'action_news_settings_add_source',
      NEWS_SETTINGS_BACK: 'action_news_settings_back',
    },
  },
  ADMIN: {
    PAGE: {
      OS_SETTINGS: 'page_admin_os_settings',
      LAYER_ADMIN: 'page_layer_admin_os_settings',
      HUBS_ADMIN: 'page_hubs_admin_os_settings',
    },
    ACTIONS: {
      OPEN_TAB: 'action_admin_page_{itemName}_click',
      REQUESTS: 'action_admin_page_requests_{itemName}_click',
      APPS_MANAGE_INTEGRATIONS: 'action_admin_page_apps_{itemName}_click',
      HIERARCHY_SETTINGS: 'action_admin_page_hierarchy_settings_click',
    },
  },
  USER: {
    PAGE: {
      PROFILE: 'page_user_profile',
    },
  },
  NETWORK: {
    ACTIONS: {
      ADD_PRIMARY_CONTACT: 'action_network_add_primary_contact',
      PRIMARY_OR_ALL_CONTACTS: 'action_network_{itemName}_contacts',
    },
  },
  OS_MODULES: {
    PRODUCT_TYPE: 'OS Module',
    PRODUCT_NAME: {
      HOME_PAGE: 'Home Page',
      PROFILE: 'Profile',
      ADMIN_SETTINGS: 'OS Settings',
      LAYER_ADMIN: 'Layer Admin Settings',
      HUBS_ADMIN: 'Hubs Admin Settings',
      MARKETPLACE: 'Marketplace',
    },
  },
}
