import { WppIconApp, WppIconExternalLink, WppListItem } from '@platform-ui-kit/components-library-react'
import { MayBeNull, NoCodeAppType } from '@wpp-open/core'
import { useNavigate } from 'react-router-dom'

import { LaunchLocations } from 'constants/analytics'
import { useCurrentTenantData } from 'providers/currentTenantData/CurrentTenantDataContext'
import { useOsState } from 'providers/osState/OsStateProvider'
import { useOtherTenantsAndUserData } from 'providers/otherTenantsAndUserData/OtherTenantsAndUserDataContext'
import { SourceLocations } from 'types/osState/appLaunchData'
import { trackAppOpenAnalytics } from 'utils/analytics'
import { AppInstanceApp } from 'utils/appInstances'
import { routesManager } from 'utils/routesManager'

interface Props {
  isChecked: boolean
  app: AppInstanceApp
  customAppName?: MayBeNull<string>
  appInstanceDevHubName?: string
  appInstanceDevHubId?: string
  productOwnerEmail?: MayBeNull<string>
  productCommercialModel?: MayBeNull<string>
  productCategory?: MayBeNull<string>
  productSubcategory?: MayBeNull<string>
}

export const BreadcrumbMenuAppItem = ({
  isChecked,
  app,
  customAppName,
  appInstanceDevHubName,
  appInstanceDevHubId,
  productOwnerEmail,
  productCommercialModel,
  productCategory,
  productSubcategory,
}: Props) => {
  const navigate = useNavigate()
  const { appData, setAppLaunchData } = useOsState()
  const { userDetails } = useOtherTenantsAndUserData()
  const { navigationTreeWithHiddenLevel } = useCurrentTenantData()

  const { activeHierarchyWithHiddenLevel, project, projectPhase, projectItem } = appData

  const appName = customAppName || app.name

  if (app.type === NoCodeAppType.ExternalLink) {
    return (
      <WppListItem
        linkConfig={{
          target: '_blank',
          rel: 'noreferrer',
          href: app.url,
        }}
        onWppChangeListItem={() => {
          trackAppOpenAnalytics({
            source: SourceLocations.Core,
            userDetails,
            launchedFrom: LaunchLocations.Breadcrumbs,
            project,
            projectItem,
            projectPhase,
            productName: appInstanceDevHubName || '',
            productType: app.type,
            productOwnerEmail,
            productCommercialModel,
            productCategory,
            productSubcategory,
            appId: appInstanceDevHubId,
            appInstanceId: app.stableId,
            appInstanceName: app.name,
            navigationTreeWithHiddenLevel,
            activeHierarchyWithHiddenLevel,
          })
        }}
        data-testid="breadcrumbs-menu-app-item"
      >
        <WppIconApp slot="left" />
        <span slot="label">{appName}</span>
        <WppIconExternalLink slot="right" />
      </WppListItem>
    )
  }

  const href = routesManager.app({
    id: app.stableId,
    osRoute: app.osRoute,
  })

  return (
    <WppListItem
      checked={isChecked}
      linkConfig={{
        href,
        onClick: e => e.preventDefault(),
      }}
      onWppChangeListItem={() => {
        if (!isChecked) {
          setAppLaunchData({
            appInstanceId: app.stableId,
            source: SourceLocations.Core,
            launchedFrom: LaunchLocations.Breadcrumbs,
          })
          navigate(href)
        }
      }}
      data-testid="breadcrumbs-menu-app-item"
    >
      <WppIconApp slot="left" />
      <span slot="label">{appName}</span>
    </WppListItem>
  )
}
