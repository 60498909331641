import { MayBeNull, OsContextActivePage } from '@wpp-open/core'

import { FullscreenMicroApp } from 'components/apps/fullscreen/microApp/FullscreenMicroApp'
import { FullscreenNoCodeApp } from 'components/apps/fullscreen/noCodeApp/FullscreenNoCodeApp'
import { ForbiddenPageError, NotFoundError } from 'components/renderError'
import { useHasPermission } from 'hooks/useHasPermission'
import { useSetActivePage } from 'hooks/useSetActivePage'
import { LegacyMicroAppContainer } from 'legacy/LegacyMicroAppContainer'
import { useCurrentTenantData } from 'providers/currentTenantData/CurrentTenantDataContext'
import { MicroAppType, SystemAppCode } from 'types/apps/microApps'
import { AppData, AppDataType, InvalidAppData, LoadingAppData } from 'types/osState/appDataResolved'

interface Props {
  appData: Exclude<AppData, InvalidAppData | LoadingAppData>
}

export const FullscreenApp = ({ appData }: Props) => {
  const { hasPermission } = useHasPermission()
  const { currentTenant } = useCurrentTenantData()
  const isPrimaryTenant = currentTenant.flags.isPrimary

  const { type, app } = appData
  const isMasterDataApp = app.type === MicroAppType.System && app.stableId === SystemAppCode.MasterData

  const getCurrentPageBySystemAppStableId = (): MayBeNull<OsContextActivePage> => {
    if (app.type !== MicroAppType.System) {
      return null
    }
    switch (app.stableId) {
      case SystemAppCode.DevHub:
        return OsContextActivePage.DevHub
      case SystemAppCode.Orchestration:
        return OsContextActivePage.Orchestration
      case SystemAppCode.Marketplace:
        return OsContextActivePage.Marketplace
      case SystemAppCode.News:
        return OsContextActivePage.News

      default:
        return null
    }
  }

  useSetActivePage(getCurrentPageBySystemAppStableId())

  if (type === AppDataType.NoCodeApp) {
    return <FullscreenNoCodeApp app={app} />
  }

  if (isMasterDataApp && !isPrimaryTenant) {
    return <NotFoundError />
  }

  if (!hasPermission(app.permission)) {
    return <ForbiddenPageError />
  }

  return (
    <>
      {type === AppDataType.ExternalLegacyMicroApp || type === AppDataType.LocalLegacyMicroApp ? (
        <LegacyMicroAppContainer appData={appData} />
      ) : (
        <FullscreenMicroApp appData={appData} />
      )}
    </>
  )
}
