import { TenantType } from '@wpp-open/core'

import { facadeApi } from 'api'
import { PaginatedResponse } from 'api/common/types'
import { TenantShort } from 'types/tenants/tenant'

interface Params {
  page?: number
  itemsPerPage?: number
  tenantType?: TenantType
}

export const fetchAvailableTenantsApi = ({ page = 1, itemsPerPage = 100, tenantType }: Params) =>
  facadeApi.get<PaginatedResponse<TenantShort>>('/tenants', {
    params: {
      page,
      itemsPerPage,
      filter: {
        ...(!!tenantType && { tenantType }),
      },
    },
  })
