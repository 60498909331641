import {
  WppActionButton,
  WppDivider,
  WppIconChevron,
  WppInput,
  WppListItem,
  WppMenuContext,
  WppMenuGroup,
  WppTypography,
} from '@platform-ui-kit/components-library-react'
import { AnalyticsActionType, MayBeNull } from '@wpp-open/core'
import { useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Instance, Props } from 'tippy.js'

import { useDeleteUserPinnedTenant } from 'api/users/mutations/useDeleteUserPinnedTenant'
import { useUpdateUserPinnedTenant } from 'api/users/mutations/useUpdateUserPinnedTenant'
import { Flex } from 'components/common/flex/Flex'
import { showRequestTenantModal } from 'components/modal/requestTenantModal/RequestTenantModal'
import { ANALYTICS_EVENTS, LaunchLocations } from 'constants/analytics'
import { ApiQueryKeys } from 'constants/apiQueryKeys'
import { Delay } from 'constants/delay'
import { useDebounceFn } from 'hooks/useDebounceFn'
import { TenantListItem } from 'layout/header/tenantSwitcher/components/TenantListItem'
import styles from 'layout/header/tenantSwitcher/TenantSwitcher.module.scss'
import { useTenantSwitcherLists } from 'layout/header/utils'
import { useCurrentTenantData } from 'providers/currentTenantData/CurrentTenantDataContext'
import { queryClient } from 'providers/osQueryClient/utils'
import { useOtherTenantsAndUserData } from 'providers/otherTenantsAndUserData/OtherTenantsAndUserDataContext'
import { useToast } from 'providers/toast/ToastProvider'
import { TenantShort } from 'types/tenants/tenant'
import { getAnalyticsEventPayload, trackAnalytics, trackAppOpenAnalytics } from 'utils/analytics'
import { isNonEmptyArray } from 'utils/common'

const MIN_TENANTS_TO_SEARCH = 8

export const TenantSwitcher = () => {
  const { t } = useTranslation()
  const { enqueueToast } = useToast()
  const popperRef = useRef<Instance<Props>>()

  const { currentTenant } = useCurrentTenantData()
  const { mutateAsync: pinTenantHandler } = useUpdateUserPinnedTenant()
  const { mutateAsync: unpinTenantHandler } = useDeleteUserPinnedTenant()
  const { requestableTenants, userDetails } = useOtherTenantsAndUserData()

  const { isExternal: isExternalUser, pinnedTenantIds, preferredTenantId } = userDetails

  const isTenantPinned = (id: string): boolean => !!pinnedTenantIds?.length && pinnedTenantIds.includes(id)

  const [search, setSearch] = useState('')
  const [isOpen, setIsOpen] = useState(false)
  const [contentRef, setContentRef] = useState<MayBeNull<HTMLDivElement>>(null)
  const [contentVisibleHeight, setContentVisibleHeight] = useState(0)

  // Observe list height to set it during search
  const [resizeObserver] = useState(
    () =>
      new ResizeObserver(entries => {
        const node = entries.at(0)?.target?.parentElement
        let height = 0

        if (node) {
          const computedStyle = getComputedStyle(node)
          const paddingY = parseFloat(computedStyle.paddingTop) + parseFloat(computedStyle.paddingBottom)

          height = Math.floor(node.clientHeight - paddingY)
        }

        if (!isNaN(height)) {
          setContentVisibleHeight(height)
        }
      }),
  )

  const refTenantSearch = useRef<HTMLWppInputElement>(null)

  useEffect(() => {
    if (isOpen) {
      if (contentRef && !search) {
        resizeObserver.observe(contentRef)

        return () => resizeObserver.unobserve(contentRef)
      }
    } else {
      setContentVisibleHeight(0)
    }
  }, [contentRef, isOpen, resizeObserver, search])

  const [pinnedTenants, sortedTenants] = useTenantSwitcherLists({ search })

  const setSearchDebounced = useDebounceFn((search?: string) => setSearch(search?.trim() || ''), Delay.Search)

  const handlePinnedStatus = async (tenant: TenantShort) => {
    const handleSuccess = (message: string) => {
      queryClient.invalidateQueries({ queryKey: [ApiQueryKeys.CURRENT_USER] })
      enqueueToast({ message, type: 'success' })
    }
    popperRef.current?.show?.()
    try {
      if (isTenantPinned(tenant.id)) {
        await unpinTenantHandler({ tenantId: tenant.id })
        handleSuccess(t('os.header.tenant_selection.unpinned_successfully'))
      } else {
        await pinTenantHandler({ tenantId: tenant.id })
        handleSuccess(t('os.header.tenant_selection.pinned_successfully'))
      }
    } catch (error) {
      enqueueToast({
        message: t('os.common.errors.error'),
        type: 'error',
      })
    }
  }

  const onAnalyticsHandler = async (tenant: TenantShort) => {
    try {
      await Promise.all([
        trackAnalytics({
          type: AnalyticsActionType.page,
          payload: getAnalyticsEventPayload(ANALYTICS_EVENTS.LIST_OF_TENANTS.PAGE.OPEN_TENANT, tenant.name),
        }),
        trackAppOpenAnalytics({
          userDetails,
          launchedFrom: LaunchLocations.ListTenants,
          productType: ANALYTICS_EVENTS.OS_MODULES.PRODUCT_TYPE,
          productName: ANALYTICS_EVENTS.OS_MODULES.PRODUCT_NAME.HOME_PAGE,
        }),
      ])
    } finally {
      window.location.href = process.env.DEV ? '/' : tenant.homeUrl
    }
  }

  const hasAvailableTenants = isNonEmptyArray(sortedTenants) || isNonEmptyArray(pinnedTenants)
  const hasTenantOptions = hasAvailableTenants || !!search
  const hasRequestOption = isNonEmptyArray(requestableTenants) && !isExternalUser
  const isSwitcherShown = hasTenantOptions || hasRequestOption
  const hasSearchBar =
    sortedTenants.length >= MIN_TENANTS_TO_SEARCH || pinnedTenants.length >= MIN_TENANTS_TO_SEARCH || !!search

  if (!isSwitcherShown) {
    return null
  }

  return (
    <WppMenuContext
      appendToListWrapper
      dropdownConfig={{
        onShow: () => setIsOpen(true),
        onCreate: i => {
          popperRef.current = i
        },
        onHidden: () => setIsOpen(false),
        popperOptions: {
          strategy: 'fixed',
        },
      }}
      externalClass={styles.contextMenu}
    >
      <WppActionButton
        variant="secondary"
        slot="trigger-element"
        data-testid="tenant_select"
        onClick={() => {
          setSearch('')
          refTenantSearch.current?.setValue('')
          trackAnalytics({
            type: AnalyticsActionType.action,
            payload: ANALYTICS_EVENTS.LIST_OF_TENANTS.ACTIONS.LIST,
          })
        }}
      >
        <WppIconChevron className={styles.triggerIcon} slot="icon-start" direction="down" />
      </WppActionButton>

      <div
        className={styles.dropdownContent}
        ref={node => setContentRef(node)}
        style={{
          minHeight: `${contentVisibleHeight}px`,
        }}
      >
        <TenantListItem
          checked
          tenant={currentTenant}
          onClickHandler={handlePinnedStatus}
          isTenantPinned={isTenantPinned(currentTenant.id)}
          isCurrentTenant={currentTenant.id === preferredTenantId}
        />

        {hasTenantOptions && (
          <>
            <WppMenuGroup
              className={styles.tenantMenuGroup}
              header={t('os.header.tenant_selection.workspaces')}
              data-testid="tenant-options"
            >
              {hasSearchBar && (
                <WppListItem disabled className={styles.searchInputItem}>
                  <WppInput
                    ref={refTenantSearch}
                    slot="label"
                    size="s"
                    type="search"
                    data-testid="tenants-search"
                    className={styles.searchInput}
                    onWppChange={({ detail }) => setSearchDebounced(detail.value)}
                    placeholder={t('os.user_profile.search_workspace_placeholder')}
                  />
                </WppListItem>
              )}
              {isNonEmptyArray(pinnedTenants) &&
                pinnedTenants.map(tenant => (
                  <TenantListItem
                    tenant={tenant}
                    isTenantPinned
                    key={tenant.id}
                    isCurrentTenant={false}
                    onClickHandler={handlePinnedStatus}
                    checked={tenant.id === currentTenant.id}
                    onWppChangeListItem={() => onAnalyticsHandler(tenant)}
                  />
                ))}
              {isNonEmptyArray(sortedTenants) &&
                sortedTenants.map(tenant => (
                  <TenantListItem
                    tenant={tenant}
                    key={tenant.id}
                    isTenantPinned={false}
                    isCurrentTenant={false}
                    onClickHandler={handlePinnedStatus}
                    checked={tenant.id === currentTenant.id}
                    onWppChangeListItem={() => onAnalyticsHandler(tenant)}
                  />
                ))}
              {!hasAvailableTenants && (
                <Flex className={styles.notFoundWrapper} direction="column" align="center" gap={8}>
                  <WppTypography type="m-strong">{t('os.header.tenant_selection.not_found')}</WppTypography>
                  <WppTypography className={styles.notFoundSecondary} type="s-body">
                    {t('os.header.tenant_selection.try_different_search')}
                  </WppTypography>
                </Flex>
              )}
            </WppMenuGroup>
          </>
        )}

        {hasRequestOption && (
          <Flex className={styles.requestAccessOption} direction="column" align="center" gap={8}>
            <WppDivider className={styles.requestAccessDivider} />
            <WppListItem
              onWppChangeListItem={() =>
                showRequestTenantModal({
                  title: t('os.request_access_modal.workspace.access_another_os'),
                  tenants: requestableTenants,
                })
              }
            >
              <span slot="label">{t('os.header.tenant_selection.request_access')}</span>
            </WppListItem>
          </Flex>
        )}
      </div>
    </WppMenuContext>
  )
}
