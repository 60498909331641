import { InlineEditMode, InputChangeEventDetail } from '@platform-ui-kit/components-library'
import { WppInlineEditCustomEvent } from '@platform-ui-kit/components-library/dist/types/components'
import { InlineEditChangeModeEventDetail } from '@platform-ui-kit/components-library/dist/types/components/wpp-inline-edit/types'
import { WppInputCustomEvent } from '@platform-ui-kit/components-library/loader'
import { WppInlineEdit, WppInlineMessage, WppInput, WppSpinner } from '@platform-ui-kit/components-library-react'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'

import { useUpdateFileApi } from 'api/files/mutations/useUpdateFileApi'
import { isConflictError } from 'api/utils'
import { Flex } from 'components/common/flex/Flex'
import { useStableCallback } from 'hooks/useStableCallback'
import styles from 'pages/files/fileDetailsModal/FileNameInput.module.scss'
import { useValidationScheme } from 'pages/files/fileDetailsModal/utils'
import { handleReloadFile, handleReloadFiles } from 'pages/files/utils'
import { useToast } from 'providers/toast/ToastProvider'

interface Props {
  name: string
  extension: string
  id: string
}

export const FileNameInput = ({ name, extension, id }: Props) => {
  const { t } = useTranslation()
  const { enqueueToast } = useToast()
  const { mutateAsync: handleFileUpdate } = useUpdateFileApi()
  const validationScheme = useValidationScheme(extension)
  const [inputMode, setInputMode] = useState<InlineEditMode>('read')
  const [errorMessage, setErrorMessage] = useState<string | null>(null)
  const [value, setValue] = useState(name)
  const [originalValue, setOriginalValue] = useState(name)
  const [isUpdating, setIsUpdating] = useState(false)

  const onValueChange = useStableCallback((event: WppInputCustomEvent<InputChangeEventDetail>) => {
    setValue(event.detail.value || '')
    setErrorMessage(null)
  })

  const onModeChange = useStableCallback(async (event: WppInlineEditCustomEvent<InlineEditChangeModeEventDetail>) => {
    const { mode, reason } = event.detail
    setInputMode(mode)

    if (mode === 'read') {
      event.detail.closePopover()
      if (reason === 'apply') {
        await doneEditing()
      } else {
        setValue(originalValue)
      }
    }
  })

  const doneEditing = useStableCallback(async () => {
    const tempValueTrimmed = value.trim()

    if (tempValueTrimmed === originalValue) {
      return
    }

    const validationResult = validationScheme.safeParse({ name: tempValueTrimmed })
    if (!validationResult.success) {
      setErrorMessage(validationResult.error.issues[0].message)
      setInputMode('edit')
      return
    }

    setErrorMessage(null)

    try {
      setIsUpdating(true)
      await handleFileUpdate({ id, data: { name: `${tempValueTrimmed}.${extension}` } })
      await handleReloadFile(id)
      await handleReloadFiles()
      setValue(tempValueTrimmed)
      setOriginalValue(tempValueTrimmed)

      enqueueToast({
        message: t('os.files.toasts.file_name_changed_success'),
        type: 'success',
      })
    } catch (e) {
      if (isConflictError(e)) {
        setErrorMessage(t('os.files.upload_file_modal.same_name_file_error'))
      } else {
        enqueueToast({
          message: t('os.common.errors.error'),
          type: 'error',
        })
      }

      setInputMode('edit')
    } finally {
      setIsUpdating(false)
    }
  })

  return (
    <Flex direction="column" gap={8}>
      <Flex direction="row" align="center">
        <WppInlineEdit
          value={value}
          mode={inputMode}
          className={styles.inlineEdit}
          onWppModeChange={onModeChange}
          data-testid="file-name-inline-edit"
        >
          <WppInput
            slot="form-element"
            name="file-name"
            value={value}
            onWppChange={onValueChange}
            messageType={errorMessage ? 'error' : undefined}
            data-testid="file-name-input"
          />
        </WppInlineEdit>
        {isUpdating && <WppSpinner data-testid="file-name-input-spinner" />}
      </Flex>
      {errorMessage && (
        <WppInlineMessage type="error" message={errorMessage} data-testid="file-name-input-error-message" />
      )}
    </Flex>
  )
}
