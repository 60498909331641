import { HierarchyContainerNodeId, MayBeNull, ProjectsContainerNodeId } from '@wpp-open/core'
import { useMemo } from 'react'

import { HierarchyTree } from 'types/hierarchy/hierarchy'

export const useWorkspaceAccountIds = (hierarchyTree: MayBeNull<HierarchyTree>) => {
  return useMemo(
    () =>
      hierarchyTree?.mapping
        ? Object.keys(hierarchyTree.mapping)
            .filter(id => ![ProjectsContainerNodeId, HierarchyContainerNodeId].includes(id))
            .sort()
        : [],
    [hierarchyTree?.mapping],
  )
}
