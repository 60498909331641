import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'

import { useCurrentTenantData } from 'providers/currentTenantData/CurrentTenantDataContext'
import { useOsState } from 'providers/osState/OsStateProvider'
import { AppDataType } from 'types/osState/appDataResolved'
import {
  BreadcrumbItemHierarchy,
  BreadcrumbItemHierarchyType,
  BreadcrumbItemProjectType,
  BreadcrumbsData,
  BreadcrumbsType,
} from 'types/osState/breadcrumbs'
import { ProjectProcessType } from 'types/projects/project'
import { routesManager } from 'utils/routesManager'

export const useBreadcrumbs = (): BreadcrumbsData => {
  const { t } = useTranslation()
  const { navigationTree } = useCurrentTenantData()
  const { appData } = useOsState()
  const { mapping } = navigationTree
  const { activeHierarchy, type, app, project, projectPhase, projectItem } = appData

  return useMemo(() => {
    const isLoading = type === AppDataType.Loading
    const isSystemApp = type === AppDataType.SystemMicroApp
    const isWorkspaceSet = activeHierarchy.length > 0
    const isInvalidWithoutWorkspace = type === AppDataType.Invalid && !isWorkspaceSet
    const isProjectSet = !!project
    const isEmpty = (isSystemApp || isInvalidWithoutWorkspace) && !isProjectSet
    const appName = projectItem?.name || app?.name || ''

    if (isLoading || isEmpty) {
      return {
        type: BreadcrumbsType.Hierarchy,
        items: [],
      }
    }

    if (isProjectSet) {
      const isLinearProject = project.processType === ProjectProcessType.Linear
      const isProjectPhaseSet = !!projectPhase

      return {
        type: isLinearProject ? BreadcrumbsType.ProjectLinear : BreadcrumbsType.ProjectFluid,
        items: [
          {
            type: BreadcrumbItemProjectType.Home,
            label: '',
          },
          {
            type: BreadcrumbItemProjectType.Projects,
            label: t('os.header.projects'),
            link: routesManager.systemApps.orchestration.projects(),
          },
          {
            type: BreadcrumbItemProjectType.Project,
            label: project.name,
            id: project.id,
            link: routesManager.systemApps.orchestration.project(project.id),
          },
          {
            type: BreadcrumbItemProjectType.Application,
            label: isProjectPhaseSet ? `${projectPhase.name} – ${appName}` : appName,
          },
        ],
      }
    }

    return {
      type: BreadcrumbsType.Hierarchy,
      items: [
        {
          type: BreadcrumbItemHierarchyType.Home,
          label: '',
        },
        ...activeHierarchy.map<BreadcrumbItemHierarchy>(nodeAzId => ({
          type: BreadcrumbItemHierarchyType.Hierarchy,
          label: mapping[nodeAzId].name || '',
          id: nodeAzId,
        })),
        {
          type: BreadcrumbItemHierarchyType.Application,
          label: appName,
        },
      ],
    }
  }, [type, activeHierarchy, project, projectItem?.name, app?.name, t, projectPhase, mapping])
}
