import { ThemeStyles } from '@platform-ui-kit/components-library'

import { AnalyticsAction } from '../analytics'
import { MayBeNull } from '../common'
import { NavigationTree } from '../mapping'
import { PermissionsRecord } from '../permissions'
import { Taxonomy } from '../taxonomy'
import { Tenant } from '../tenant'
import { UserDetails } from '../user'

export type OpenMenuHandler = (preselectedNodeAzId?: MayBeNull<string>) => void
export type OpenAppHandler = (
  appInstanceId: string,
  params?: {
    analytics?: {
      launchedFrom: string
    }
  },
) => void
export type OpenCompactAppHandler = (params: {
  name: string
  url: string
  overlayProps: Record<string, unknown>
}) => void

export type AppContextUnsubscribeFn = () => void

export enum AppErrorType {
  ForbiddenPage = 'Forbidden page',
  CriticalError = 'Critical error',
}

export enum OsContextActivePage {
  Promo = 'PROMO_PAGE',
  /**
   * @deprecated Home page was transformed into hubs
   */
  Home = 'HOME_PAGE',
  Network = 'NETWORK_PAGE',
  Hubs = 'HUBS_PAGE',
  HubsAdmin = 'HUBS_ADMIN_PAGE',
  // Any pages related to the marketplace
  Marketplace = 'MARKETPLACE_PAGE',
  // Any pages related to DevHub
  DevHub = 'DEVHUB_PAGE',
  // Any pages related to Orchestration
  Orchestration = 'ORCHESTRATION_PAGE',
  // Any pages in OS Settings
  OsSettings = 'OS_SETTINGS_PAGE',
  LayerAdmin = 'LAYER_ADMIN_PAGE',
  UserProfile = 'USER_PROFILE_PAGE',
  Links = 'LINKS_PAGE',
  News = 'NEWS_PAGE',
  PageNotFound = 'NOT_FOUND_PAGE',
  PageForbidden = 'FORBIDDEN_PAGE',
}

export type TriggerErrorHandler = (errorType: AppErrorType) => void

export interface OsCommonContext {
  /**
   * Information about the tenant the app is launched in.
   */
  tenant: Tenant
  /**
   * List of user permissions.
   */
  permissions: PermissionsRecord[]
  /**
   * Information about the user who launched the app.
   */
  userDetails: UserDetails
  /**
   * Unpacked theme object with resolved color values.
   * May be used to provide external libraries with theme colors.
   */
  theme: ThemeStyles
  /**
   * A tree of hierarchy and project nodes the user has access to within a current tenant.
   * Includes a hidden hierarchy level if the tenant is configured accordingly.
   */
  navigationTree: NavigationTree
  /**
   * List of known taxonomy entities to be used in i18n.
   */
  taxonomy: Taxonomy
  /**
   * A page identifier present for specific pages, it is empty in all other cases.
   */
  activePage: MayBeNull<OsContextActivePage>
}

export interface OsApiContext {
  getAccessToken: () => string
  navigation: {
    openMenu: OpenMenuHandler
    openApp: OpenAppHandler
    openCompactApp: OpenCompactAppHandler
    triggerError: TriggerErrorHandler
  }
  analytics: {
    track: (data: AnalyticsAction) => void
  }
}
