import { MayBeNull } from '@wpp-open/core'
import { addMilliseconds, addMinutes, eachMinuteOfInterval, format, isAfter, isBefore, isSameDay, set } from 'date-fns'
import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import * as zod from 'zod'

import { ApiQueryKeys } from 'constants/apiQueryKeys'
import { Permission } from 'constants/permission'
import { useHasPermission } from 'hooks/useHasPermission'
import { useStableCallback } from 'hooks/useStableCallback'
import { showImportEventsSideModal } from 'pages/home/systemWidgets/calendarWidget/importEventsSideModal/ImportEventsSideModal'
import { showManageEventSideModal } from 'pages/home/systemWidgets/calendarWidget/manageEventSideModal/ManageEventSideModal'
import { getDate, isAllDayEvent, TIME_FORMAT } from 'pages/home/systemWidgets/calendarWidget/utils'
import {
  hideViewEventsSideModal,
  showViewEventsSideModal,
} from 'pages/home/systemWidgets/calendarWidget/viewEventsSideModal/ViewEventsSideModal'
import { queryClient } from 'providers/osQueryClient/utils'
import { CalendarWidgetEventDTO } from 'types/widgets/widget'
import { convertToUTCFromUserTimezone } from 'utils/date'

const NAME_CHARACTERS_LIMIT = 64
const LINK_CHARACTERS_LIMIT = 2048
export const DESCRIPTION_CHARACTERS_LIMIT = 4096
export const LOCATION_CHARACTERS_LIMIT = 60
export const TIME_STEP = 30
export const TIMES_LIST_ITEM_MAX_HOUR = 23
export const RESPONSE_TIME_FORMAT = 'HH:mm:ss.SSS'

export const defaultTime = { hours: 0, minutes: 0 }

enum IssueCodes {
  InvalidType = 'invalid_type',
}

export interface AddEventModalFormValues {
  name: string
  startDate: Date
  endDate: Date
  description: string
  location: string
  videoConferencingUrl: string
  startTime: string
  endTime: string
  color: string
  allDay: boolean
}

export const getDefaultValues = (
  event: MayBeNull<CalendarWidgetEventDTO> = null,
  selectedDate?: Date,
): AddEventModalFormValues => {
  return {
    name: event?.title || '',
    ...getDefaultFormDatesValues(event, selectedDate),
    allDay: !!event?.startsAt && !!event?.endsAt && isAllDayEvent(new Date(event.startsAt), new Date(event.endsAt)),
    description: event?.description || '',
    location: event?.location || '',
    videoConferencingUrl: event?.videoConferencingUrl || '',
    color:
      event?.color || getComputedStyle(document.documentElement).getPropertyValue('--wpp-dataviz-color-cat-neutral-1'),
  }
}

export const setEventValues = (values: AddEventModalFormValues) => ({
  title: values.name,
  description: values.description || null,
  startsAt: convertToUTCFromUserTimezone(
    `${getDate(values.startDate)}T${format(new Date(values.startTime), RESPONSE_TIME_FORMAT)}`,
  ),
  endsAt: convertToUTCFromUserTimezone(
    `${getDate(values.endDate)}T${format(addMilliseconds(new Date(values.endTime), 1), RESPONSE_TIME_FORMAT)}`,
  ),
  color: values.color,
  location: values.location || null,
  videoConferencingUrl: values.videoConferencingUrl || null,
})

export const handleReloadCalendarEvents = async () => {
  await Promise.all([
    queryClient.invalidateQueries({ queryKey: [ApiQueryKeys.CALENDAR_WIDGET_EVENTS] }),
    queryClient.invalidateQueries({ queryKey: [ApiQueryKeys.CALENDAR_WIDGET_ALL_EVENTS] }),
  ])
}

export const useValidationScheme = () => {
  const { t } = useTranslation()

  return zod.object({
    name: zod
      .string()
      .trim()
      .min(1, t('os.home.calendar_widget.manage_event.validation.name.nonempty'))
      .max(
        NAME_CHARACTERS_LIMIT,
        t('os.home.calendar_widget.manage_event.validation.name.max', { characterCount: NAME_CHARACTERS_LIMIT }),
      ),
    startDate: zod.date({
      errorMap: (issue, { defaultError }) => ({
        message:
          issue.code === IssueCodes.InvalidType
            ? t('os.home.calendar_widget.manage_event.validation.date.nonempty')
            : defaultError,
      }),
    }),
    endDate: zod.date({
      errorMap: (issue, { defaultError }) => ({
        message:
          issue.code === IssueCodes.InvalidType
            ? t('os.home.calendar_widget.manage_event.validation.date.nonempty')
            : defaultError,
      }),
    }),
    startTime: zod.string(),
    endTime: zod.string(),
    allDay: zod.boolean(),
    color: zod.string(),
    description: zod.string().max(
      DESCRIPTION_CHARACTERS_LIMIT,
      t('os.home.calendar_widget.manage_event.validation.description.max', {
        characterCount: DESCRIPTION_CHARACTERS_LIMIT,
      }),
    ),
    location: zod
      .string()
      .trim()
      .max(
        LOCATION_CHARACTERS_LIMIT,
        t('os.home.calendar_widget.manage_event.validation.location.max', {
          characterCount: LOCATION_CHARACTERS_LIMIT,
        }),
      ),
    videoConferencingUrl: zod
      .string()
      .regex(
        /^$|^(?:(https):\/\/)(?:www\.)?[a-z0-9-]+(?:\.[a-z0-9-]+)+[^\s]*$/i,
        t('os.home.calendar_widget.manage_event.validation.video_link.invalid'),
      )
      .max(
        LINK_CHARACTERS_LIMIT,
        t('os.home.calendar_widget.manage_event.validation.video_link.max', { characterCount: LINK_CHARACTERS_LIMIT }),
      ),
  })
}

const getEachTimeStepOfDay = (date: Date): Date[] => {
  return eachMinuteOfInterval(
    {
      // range from 00:00 till 23:30
      start: new Date(date.getFullYear(), date.getMonth(), date.getDate()),
      end: new Date(date.getFullYear(), date.getMonth(), date.getDate(), TIMES_LIST_ITEM_MAX_HOUR, TIME_STEP),
    },
    {
      step: TIME_STEP,
    },
  )
}

export const getDefaultFormDatesValues = (
  event: MayBeNull<CalendarWidgetEventDTO>,
  selectedDate?: Date,
): Omit<AddEventModalFormValues, 'allDay' | 'description' | 'location' | 'color' | 'name' | 'videoConferencingUrl'> => {
  const predefinedDate = selectedDate || null
  const startsAt = event?.startsAt ? new Date(event.startsAt) : getDefaultDatesConfig(predefinedDate)
  const endsAt = event?.endsAt ? new Date(event.endsAt) : getDefaultDatesConfig(predefinedDate, TIME_STEP)

  return {
    startDate: startsAt,
    endDate: endsAt,
    startTime: startsAt.toString(),
    endTime: endsAt.toString(),
  }
}

export const getDefaultDatesConfig = (predefinedDate: Date | null, step = 0): Date => {
  const date = new Date()
  return addMinutes(
    predefinedDate
      ? predefinedDate
      : getEachTimeStepOfDay(
          // in the case datetime is greater than 23:30 - the range for the next day should be calculated
          !isSameDay(addMinutes(date, TIME_STEP), date) ? set(addMinutes(date, TIME_STEP), { minutes: 0 }) : date,
        ).find(datetime => isAfter(datetime, date))!,
    step,
  )
}

export const useGetTimesListOptions = (startTime: string, startDate?: Date, endDate?: Date): any => {
  const getTimesList = useStableCallback((date: Date) =>
    getEachTimeStepOfDay(date).map(datetime => ({
      id: datetime.toString(),
      label: format(datetime, TIME_FORMAT),
    })),
  )

  return useMemo(() => {
    const startTimeListOptions = startDate ? getTimesList(startDate) : []
    const endTimeListOptions = endDate
      ? getTimesList(endDate).filter(({ id }) => !isBefore(new Date(id), new Date(startTime)))
      : []
    return {
      startTimeListOptions,
      endTimeListOptions,
    }
  }, [startDate, endDate, startTime, getTimesList])
}

export const useRichtextConfig = () => {
  const { hasPermission } = useHasPermission()

  return useMemo(() => {
    const config = [
      [{ size: [] }],
      ['bold', 'italic', 'underline', 'strike'],
      [{ list: 'bullet' }, { list: 'ordered' }, { indent: '+1' }, { indent: '-1' }],
      ['undo', 'redo'],
    ]

    if (hasPermission(Permission.OS_ADMIN_SETTING_ACCESS)) {
      // Insert possibility to add the link only for the Admin users
      config.splice(3, 0, ['link'])
    }

    return JSON.stringify({ toolbar: config })
  }, [hasPermission])
}

export const useViewEventsModals = ({ date, calendarId }: { date?: Date; calendarId: string }) => {
  return useMemo(
    () => ({
      onAddEvent: () =>
        showManageEventSideModal({
          onOpen: hideViewEventsSideModal,
          onClose: () => showViewEventsSideModal({ calendarId }),
          selectedDate: date,
          calendarId,
        }),
      onImportEvents: () =>
        showImportEventsSideModal({
          onOpen: hideViewEventsSideModal,
          onClose: () => showViewEventsSideModal({ calendarId }),
          calendarId,
        }),
    }),
    [date, calendarId],
  )
}
