import { WppSkeleton } from '@platform-ui-kit/components-library-react'

import { Flex } from 'components/common/flex/Flex'
import styles from 'components/treeList/loadingTreeList/LoadingTreeList.module.scss'
import { emptyArray } from 'utils/common'

interface Props {
  rows?: number
}

export const LoadingTreeList = ({ rows = 3 }: Props) => (
  <Flex as="ul" direction="column" gap={2} data-testid="loading-tree-list-state">
    {emptyArray(rows).map((_, index) => (
      <li key={index} className={styles.listItem}>
        <WppSkeleton width="55%" height={20} />
      </li>
    ))}
  </Flex>
)
