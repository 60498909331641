import { userDetailsApi } from 'api'

interface Params {
  hubId: string
  tenantId: string
}

export const updateUserPinnedHubApi = ({ hubId, tenantId }: Params) =>
  userDetailsApi.patch(`/users/me/hubs/${hubId}/pin`, null, {
    headers: {
      'X-Tenant-Id': tenantId,
    },
  })
