import { MayBeNull } from '@wpp-open/core'
import { AgGridReact } from 'ag-grid-react'

import { TableKey } from 'constants/table'

type GetInstanceFn = () => MayBeNull<AgGridReact>

const tablesMap = new Map<TableKey, GetInstanceFn>()

export const registerTable = (tableKey: TableKey, getInstance: GetInstanceFn) => {
  tablesMap.set(tableKey, getInstance)

  return () => {
    tablesMap.delete(tableKey)
  }
}

export const tableActions = {
  reload: (key: TableKey | TableKey[]) => {
    const tables = [key].flat().map(tableKey => tablesMap.get(tableKey)?.())

    tables.forEach(table => {
      if (table) {
        const count = table.api.getDisplayedRowCount()
        table.api.setRowCount(count, false)
        table.api.purgeInfiniteCache()
      }
    })
  },
  deselectAll: (key: TableKey | TableKey[]) => {
    const tables = [key].flat().map(tableKey => tablesMap.get(tableKey)?.())

    tables.forEach(table => {
      if (table) {
        const count = table.api.getSelectedRows().length

        // Ignore deselection if no rows were selected
        if (count) {
          table.api.deselectAll()
        }
      }
    })
  },
  selectByPredicate: <T>(key: TableKey | TableKey[], predicate: (row: T) => boolean) => {
    const tables = [key].flat().map(tableKey => tablesMap.get(tableKey)?.())

    tables.forEach(table => {
      if (table) {
        table.api.forEachNode(node => {
          if (predicate(node.data)) {
            node.setSelected(true)
          }
        })
      }
    })
  },
  deselectByIds: (key: TableKey, ids: string[]) => {
    const tables = [key].flat().map(tableKey => tablesMap.get(tableKey)?.())
    tables.forEach(table => {
      if (table) {
        table.api.forEachNode(node => {
          if (ids.includes(node.id!)) {
            node.setSelected(false)
          }
        })
      }
    })
  },
}
