import { WppTopbar } from '@platform-ui-kit/components-library-react'
import clsx from 'clsx'
import { ComponentProps, useLayoutEffect, useRef } from 'react'

import styles from 'layout/subheader/Subheader.module.scss'

export const Subheader = ({ className, ...rest }: ComponentProps<typeof WppTopbar>) => {
  const topbarRef = useRef<HTMLWppTopbarElement>(null)

  // Since grid container doesn't have part name in shadow dom, we need to style it manually (until fixed in WPPLONOP-27277)
  useLayoutEffect(() => {
    const shadowHost = topbarRef.current
    if (shadowHost) {
      const shadowRoot = shadowHost.shadowRoot
      if (shadowRoot) {
        const style = document.createElement('style')
        style.textContent = `
            :host .wrapper .container.row {
              margin-left: auto;
            }
          `
        shadowRoot.appendChild(style)
      }
    }
  }, [])

  return <WppTopbar ref={topbarRef} className={clsx('os-subheader', styles.topbar, className)} {...rest} />
}
