import {
  WppActionButton,
  WppIconAdd,
  WppIconUpload,
  WppInput,
  WppSpinner,
  WppTooltip,
  WppTypography,
} from '@platform-ui-kit/components-library-react'
import { t } from 'i18next'
import { RefCallback, useCallback, useMemo, useState } from 'react'

import { useInfiniteFetchCalendarWidgetEventsApi } from 'api/calendars/infiniteQueries/useInfiniteFetchCalendarWidgetEventsApi'
import { Flex } from 'components/common/flex/Flex'
import { SideModal } from 'components/surface/sideModal/SideModal'
import { Delay } from 'constants/delay'
import { Permission } from 'constants/permission'
import { useDebounceFn } from 'hooks/useDebounceFn'
import { useHasPermission } from 'hooks/useHasPermission'
import { useInfiniteFetchNextPage } from 'hooks/useInfiniteFetchNextPage'
import { EventsCard, EventsCardSkeleton } from 'pages/home/systemWidgets/calendarWidget/eventsCard/EventsCard'
import { EventsEmptyState } from 'pages/home/systemWidgets/calendarWidget/EventsEmptyState'
import { useViewEventsModals } from 'pages/home/systemWidgets/calendarWidget/manageEventSideModal/utils'
import {
  EventsGroup,
  getDate,
  GroupedEvents,
  useGroupByDateEvents,
} from 'pages/home/systemWidgets/calendarWidget/utils'
import { NoResultsState } from 'pages/home/systemWidgets/calendarWidget/viewEventsSideModal/NoResultsState'
import styles from 'pages/home/systemWidgets/calendarWidget/viewEventsSideModal/ViewEventsSideModal.module.scss'
import { useHubId } from 'pages/hubs/utils'
import { useCurrentTenantData } from 'providers/currentTenantData/CurrentTenantDataContext'
import { emptyArray } from 'utils/common'
import { NiceModalWrappedProps, createNiceModal } from 'utils/createNiceModal'

const Skeleton = () => (
  <Flex direction="column" gap={24}>
    {emptyArray(5).map((_, index) => (
      <EventsCardSkeleton key={index} />
    ))}
  </Flex>
)

interface Props extends NiceModalWrappedProps {
  currentMonthDate?: Date
  calendarId: string
}

const ViewEventsSideModal = ({
  id,
  isOpen,
  onClose,
  calendarId,
  onCloseComplete,
  currentMonthDate = new Date(),
}: Props) => {
  const { hasPermission } = useHasPermission()

  const hubId = useHubId()
  const { currentTenant } = useCurrentTenantData()

  const [search, setSearch] = useState('')

  const { onAddEvent, onImportEvents } = useViewEventsModals({ calendarId })

  const setSearchDebounced = useDebounceFn((search?: string) => setSearch(search?.trim() || ''), Delay.Search)

  const minDate = useMemo(
    () =>
      getDate(new Date(currentMonthDate.getFullYear(), currentMonthDate.getMonth() - 1, currentMonthDate.getDate())),
    [currentMonthDate],
  )

  const { data, hasNextPage, fetchNextPage, isFetching, isLoading } = useInfiniteFetchCalendarWidgetEventsApi({
    initialPageParam: {
      page: 1,
    },
    params: {
      search,
      minDate,
      calendarId,
      hub_id: hubId,
      itemsPerPage: 100,
      tenantId: currentTenant.id,
    },
    staleTime: 60 * 1000,
  })

  const groupedData = useGroupByDateEvents(data)

  const [loadMoreRef, setLoadMoreRef] = useState<HTMLDivElement>(null!)
  const setRef: RefCallback<HTMLDivElement> = useCallback(node => {
    setLoadMoreRef(node!)
  }, [])

  useInfiniteFetchNextPage({
    loadMoreRef,
    isFetching,
    fetchNextPage,
    hasNextPage,
  })

  return (
    <SideModal
      open={isOpen}
      onWppSideModalClose={onClose}
      onWppSideModalCloseComplete={onCloseComplete}
      size="m"
      disableOutsideClick
      data-testid={id}
      className={styles.modal}
    >
      <Flex slot="header" justify="between" data-testid="modal-header-container">
        <WppTypography slot="header" type="2xl-heading" data-testid="modal-header-container-title">
          {t('os.home.calendar_widget.view_events.title')}
        </WppTypography>
        {hasPermission(Permission.OS_ADMIN_SETTING_ACCESS) && (
          <Flex gap={8}>
            <WppTooltip text={t('os.home.calendar_widget.view_events.tooltip_add_event')}>
              <WppActionButton variant="secondary" onClick={onAddEvent}>
                <WppIconAdd slot="icon-start" />
              </WppActionButton>
            </WppTooltip>

            <WppTooltip text={t('os.home.calendar_widget.view_events.tooltip_import_events')}>
              <WppActionButton variant="secondary" onClick={onImportEvents}>
                <WppIconUpload slot="icon-start" />
              </WppActionButton>
            </WppTooltip>
          </Flex>
        )}
      </Flex>

      <Flex slot="body" gap={28} direction="column" className={styles.body}>
        <WppInput
          size="s"
          type="search"
          onWppChange={({ detail }) => setSearchDebounced(detail.value)}
          placeholder={t('os.home.calendar_widget.view_events.search_placeholder')}
        />
        <Flex direction="column" gap={10} className={styles.bodyEvents} data-testid="events-container-scrollable">
          <Flex direction="column" gap={24} data-testid="events-container-grouped-by-date">
            {groupedData.map((group: EventsGroup) => {
              return (
                <div key={group[GroupedEvents.Group]}>
                  <EventsCard
                    key={group[GroupedEvents.Group]}
                    events={group[GroupedEvents.Events]}
                    groupName={group[GroupedEvents.Group]}
                    data-testid="event-card"
                  />
                </div>
              )
            })}
          </Flex>

          {isLoading && <Skeleton />}

          <Flex justify="center" ref={setRef} className={styles.spinner}>
            {isFetching && !isLoading && <WppSpinner size="m" />}
          </Flex>

          {!isLoading && !search && !data.length && (
            <EventsEmptyState className={styles.noResultsWrapper} onAddEvent={onAddEvent} />
          )}
          {!isLoading && search && !data.length && <NoResultsState search={search} />}
        </Flex>
      </Flex>
    </SideModal>
  )
}

export const { showModal: showViewEventsSideModal, hideModal: hideViewEventsSideModal } = createNiceModal(
  ViewEventsSideModal,
  'calendar-widget-view-events-side-modal',
)
