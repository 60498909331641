import { TenantType } from '@wpp-open/core'
import qs from 'qs'

import { facadeApi } from 'api'
import { PaginatedResponse } from 'api/common/types'
import { TenantPublicShort } from 'types/tenants/tenant'

interface Params {
  page?: number
  itemsPerPage?: number
  tenantType?: TenantType
  search?: string
  sort?: string
}

export const fetchAllTenantsApi = ({ page = 1, itemsPerPage = 100, tenantType, search, sort }: Params) =>
  facadeApi.get<PaginatedResponse<TenantPublicShort>>('/tenants/all', {
    params: {
      page,
      itemsPerPage,
      sort,
      filter: {
        ...(!!tenantType && { tenantType }),
        ...(!!search && { search }),
      },
    },
    paramsSerializer: {
      serialize: params => qs.stringify(params, { arrayFormat: 'repeat', encode: true }),
    },
  })
