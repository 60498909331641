import { WppMenuContext } from '@platform-ui-kit/components-library-react'
import { AppInstanceAssignmentType, AppInstanceStatus, AppInstanceShort } from '@wpp-open/core'
import { ComponentPropsWithoutRef, ReactNode, useMemo, useState } from 'react'

import { useAppInstancesApi } from 'api/apps/queries/useAppInstancesApi'
import { Flex } from 'components/common/flex/Flex'
import { AppInstancesDefaults } from 'constants/appInstances'
import { PageSize } from 'constants/pageSize'
import { ProjectBreadcrumbsAppsProvider } from 'layout/header/breadcrumbs/project/appsProvider/ProjectBreadcrumbsAppsProvider'
import { useOsState } from 'providers/osState/OsStateProvider'
import { mapAppInstanceToApp } from 'utils/appInstances'

type WppMenuContextProps = Omit<ComponentPropsWithoutRef<typeof WppMenuContext>, 'children'>

interface Props extends WppMenuContextProps {
  trigger: ReactNode
  menu: ReactNode
}

export interface BreadcrumbProjectAppInstancesData {
  [k: string]: AppInstanceShort
}

export const ProjectBreadcrumbsMenuContext = ({ trigger, menu, listWidth, dropdownConfig, ...rest }: Props) => {
  const { appData } = useOsState()
  const [isOpen, setIsOpen] = useState(false)

  const { project } = appData

  const { data: appInstances, isLoading } = useAppInstancesApi({
    params: {
      assignmentId: project?.id,
      assignmentType: AppInstanceAssignmentType.Project,
      status: [AppInstanceStatus.Active],
      itemsPerPage: PageSize.All,
    },
    staleTime: AppInstancesDefaults.BreadcrumbsStaleTime,
    enabled: isOpen && !!project,
  })

  const appsInstances: BreadcrumbProjectAppInstancesData = useMemo(() => {
    const visibleFilteredAppInstances = appInstances.filter(app => app.flags.visible)

    return Object.fromEntries(visibleFilteredAppInstances.map(appInstance => [appInstance.id, appInstance]))
  }, [appInstances])

  const appsMap = useMemo(
    () => Object.fromEntries(appInstances.map(appInstance => [appInstance.id, mapAppInstanceToApp(appInstance)])),
    [appInstances],
  )

  const wrappedDropdownConfig: WppMenuContextProps['dropdownConfig'] = useMemo(
    () => ({
      ...dropdownConfig,
      onShow: () => {
        setIsOpen(true)
      },
      onHidden: () => {
        setIsOpen(false)
      },
    }),
    [dropdownConfig],
  )

  return (
    <WppMenuContext {...rest} listWidth={listWidth} dropdownConfig={wrappedDropdownConfig}>
      {trigger}

      <Flex direction="column">
        <ProjectBreadcrumbsAppsProvider appsMap={appsMap} isLoading={isLoading} appsInstances={appsInstances}>
          <>{isOpen ? menu : null}</>
        </ProjectBreadcrumbsAppsProvider>
      </Flex>
    </WppMenuContext>
  )
}
