import { WppButton, WppTypography } from '@platform-ui-kit/components-library-react'
import { FormProvider } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

import { useCreateSignUpRequestApi } from 'api/signUp/mutations/useCreateSignUpRequestApi'
import { isConflictError } from 'api/utils'
import { Flex } from 'components/common/flex/Flex'
import { FormTextInput } from 'components/form/formTextInput/FormTextInput'
import { showCancelChangesModal } from 'components/modal/cancelChangesModal/CancelChangesModal'
import { showTenantAlreadyRequestedModal } from 'components/modal/tenantAlreadyRequestedModal/TenantAlreadyRequestedModal'
import { SideModal } from 'components/surface/sideModal/SideModal'
import { useForm } from 'hooks/form/useForm'
import styles from 'pages/login/loginView/LoginView.module.scss'
import { defaultValues, useSignUpValidationScheme } from 'pages/login/signUpSideModal/utils'
import { useToast } from 'providers/toast/ToastProvider'
import { createNiceModal, NiceModalWrappedProps } from 'utils/createNiceModal'

interface Props extends NiceModalWrappedProps {
  tenantId: string
  title: string
}

const SignUpSideModal = ({ isOpen, onClose, onCloseComplete, id, tenantId, title }: Props) => {
  const { t } = useTranslation()
  const { enqueueToast } = useToast()
  const { mutateAsync: handleSignUpRequest } = useCreateSignUpRequestApi()

  const form = useForm({
    defaultValues,
    validationSchema: useSignUpValidationScheme(),
  })

  const {
    handleSubmit,
    formState: { isSubmitting, isDirty },
  } = form

  const onCancel = () => {
    if (isDirty) {
      showCancelChangesModal({
        continueButtonText: t('os.common.continue'),
        onCancel: onClose,
      })
    } else {
      onClose()
    }
  }

  const onSubmit = handleSubmit(async values => {
    try {
      await handleSignUpRequest({ tenantId, userData: values })

      enqueueToast({
        message: t('os.sign_up_modal.toast_success'),
        type: 'success',
      })
      onClose()
    } catch (error) {
      if (isConflictError(error)) {
        showTenantAlreadyRequestedModal()
        onClose()
      } else {
        enqueueToast({
          message: t('os.common.errors.error'),
          type: 'error',
        })
      }
    }
  })

  return (
    <FormProvider {...form}>
      <SideModal
        data-testid={id}
        formConfig={{ onSubmit }}
        open={isOpen}
        size="m"
        disableOutsideClick
        onWppSideModalClose={onCancel}
        onWppSideModalCloseComplete={onCloseComplete}
      >
        <>
          <WppTypography slot="header" type="2xl-heading">
            {title}
          </WppTypography>

          <Flex slot="body" direction="column" gap={24}>
            <Flex justify="between" gap={24}>
              <FormTextInput
                name="firstName"
                labelConfig={{
                  text: t('os.sign_up_modal.full_name'),
                }}
                className={styles.input}
                placeholder={t('os.admin.tenants.modal.first_name')}
                required
              />
              <FormTextInput
                name="lastName"
                labelConfig={{
                  text: '\u2008',
                }}
                className={styles.input}
                placeholder={t('os.admin.tenants.modal.last_name')}
                required
              />
            </Flex>
            <FormTextInput
              name="email"
              labelConfig={{
                text: t('os.sign_up_modal.work_email'),
              }}
              className={styles.input}
              placeholder={t('os.sign_up_modal.email_placeholder')}
              required
            />
          </Flex>

          <Flex slot="actions" gap={12} justify="end">
            <WppButton variant="secondary" onClick={onCancel} data-testid="cancel">
              {t('os.common.cancel')}
            </WppButton>
            <WppButton variant="primary" type="submit" loading={isSubmitting} data-testid="apply">
              {t('os.sign_up_modal.sign_up')}
            </WppButton>
          </Flex>
        </>
      </SideModal>
    </FormProvider>
  )
}

export const { showModal: showSignUpSideModal } = createNiceModal<Props>(SignUpSideModal, 'sign-up-side-modal')
