import { AttachmentMetadata } from '../attachments'
import { MayBeNull } from '../common'
import { CustomHierarchyLevelType, DefaultHierarchyLevelType } from '../tenant'

export const HierarchyCustomNodeType = 'CUSTOM'
export type HierarchyCustomNodeType = typeof HierarchyCustomNodeType

export const ContainerNodeType = 'CONTAINER'
export type ContainerNodeType = typeof ContainerNodeType

export const HierarchyContainerNodeId = 'hierarchy-container-node'
export type HierarchyContainerNodeId = typeof HierarchyContainerNodeId

export interface HierarchyContainerNode {
  azId: null
  type: ContainerNodeType
  customTypeName: null
  name: HierarchyContainerNodeId
  logoOriginal: null
  logoThumbnail: null
  children: string[]
  apps: readonly []
}

export type HierarchyNodeType = DefaultHierarchyLevelType | HierarchyCustomNodeType

export interface HierarchyNode {
  azId: string
  type: HierarchyNodeType
  /**
   * This field is always non-null for custom node types
   */
  customTypeName: MayBeNull<CustomHierarchyLevelType>
  name: string
  logoOriginal: MayBeNull<AttachmentMetadata>
  logoThumbnail: MayBeNull<AttachmentMetadata>
  children: string[]
  apps: readonly []
  alias: MayBeNull<string>
}
