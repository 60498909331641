import { createContext, useContext } from 'react'

import { AssignmentType } from 'types/files/files'

export interface FilesOptionsContextValue {
  isAdmin: boolean
  isFavorite?: boolean
  folderId?: string
  assignmentId?: string
  assignmentType?: AssignmentType
}

export const FilesOptionsContext = createContext<FilesOptionsContextValue>(null!)
export const useFilesOptions = () => useContext(FilesOptionsContext)
