import { WppButton, WppTypography } from '@platform-ui-kit/components-library-react'
import { useMemo } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { useAsyncFn } from 'react-use'

import { useBulkDeleteFilesApi } from 'api/files/mutations/useBulkDeleteFilesApi'
import { Flex } from 'components/common/flex/Flex'
import { tableActions } from 'components/common/table'
import { Modal } from 'components/surface/modal/Modal'
import { TableKey } from 'constants/table'
import { handleReloadFiles } from 'pages/files/utils'
import { useToast } from 'providers/toast/ToastProvider'
import { FileItem, FileItemType } from 'types/files/files'
import { createNiceModal, NiceModalWrappedProps } from 'utils/createNiceModal'

interface Props extends NiceModalWrappedProps {
  items: FileItem[]
  onDelete?: () => void
}

export const DeleteFileItemsModal = ({ isOpen, items, id, onClose, onCloseComplete, onDelete }: Props) => {
  const { t } = useTranslation()
  const { enqueueToast } = useToast()
  const onlyFiles = items.every(({ type }) => type === FileItemType.REGULAR)
  const onlyOne = items.length === 1
  const ids = items.map(({ id }) => id)

  const title = useMemo(() => {
    if (onlyOne) {
      if (onlyFiles) {
        return t('os.files.delete_modal.title_one_file')
      }
      return t('os.files.delete_modal.title_one_folder')
    } else {
      if (onlyFiles) {
        return t('os.files.delete_modal.title_files')
      }
      return t('os.files.delete_modal.title_selection')
    }
  }, [onlyFiles, onlyOne, t])

  const toastSuccessMessage = useMemo(() => {
    if (onlyOne) {
      if (onlyFiles) {
        return t('os.files.toasts.one_file_deleted', { fileName: items[0].name })
      }
      return t('os.files.toasts.one_folder_deleted', { folderName: items[0].name })
    } else {
      if (onlyFiles) {
        return t('os.files.toasts.files_deleted', { amount: items.length })
      }
      return t('os.files.toasts.selection_deleted')
    }
  }, [items, onlyFiles, onlyOne, t])

  const description = useMemo(() => {
    if (onlyOne) {
      const name = items[0].name
      if (onlyFiles) {
        return (
          <Trans
            i18nKey="os.files.delete_modal.description_one_file"
            values={{
              fileName: `<0>${name}</0>`,
            }}
            components={[
              <WppTypography key="0" type="s-strong">
                {name}
              </WppTypography>,
            ]}
          />
        )
      } else {
        return (
          <Trans
            i18nKey="os.files.delete_modal.description_one_folder"
            values={{
              folderName: `<0>${name}</0>`,
            }}
            components={[
              <WppTypography key="0" type="s-strong">
                {name}
              </WppTypography>,
            ]}
          />
        )
      }
    } else {
      const amount = items.length
      if (onlyFiles) {
        return (
          <Trans
            i18nKey="os.files.delete_modal.description_files"
            values={{
              amount: `<0>${amount}</0>`,
            }}
            components={[
              <WppTypography key="0" type="s-strong">
                {amount}
              </WppTypography>,
            ]}
          />
        )
      } else {
        return t('os.files.delete_modal.description_selection')
      }
    }
  }, [items, onlyFiles, onlyOne, t])

  const { mutateAsync: handleDeleteFiles } = useBulkDeleteFilesApi()

  const [{ loading: isDeleting }, onRemove] = useAsyncFn(async () => {
    try {
      await handleDeleteFiles(ids)
      tableActions.deselectByIds(TableKey.FILES, ids)
      await handleReloadFiles()
      enqueueToast({
        message: toastSuccessMessage,
        type: 'success',
      })
      onDelete?.()
    } catch {
      enqueueToast({
        message: t('os.common.errors.error'),
        type: 'error',
      })
    } finally {
      onClose()
    }
  }, [handleDeleteFiles, ids, enqueueToast, toastSuccessMessage, onDelete, t, onClose])

  return (
    <Modal
      open={isOpen}
      onWppModalClose={onClose}
      onWppModalCloseComplete={onCloseComplete}
      disableOutsideClick={isDeleting}
      data-testid={id}
    >
      <Flex slot="header" align="center" gap={9}>
        <WppTypography type="xl-heading">{title}</WppTypography>
      </Flex>

      <WppTypography slot="body" type="s-body">
        {description}
      </WppTypography>

      <Flex slot="actions" justify="end" gap={12}>
        <WppButton variant="secondary" size="s" onClick={onClose} data-testid="cancel">
          {t('os.common.cancel')}
        </WppButton>
        <WppButton size="s" variant="destructive" loading={isDeleting} onClick={onRemove} data-testid="delete">
          {t('os.common.delete')}
        </WppButton>
      </Flex>
    </Modal>
  )
}

export const { showModal: showDeleteFileItemsModal } = createNiceModal(DeleteFileItemsModal, 'delete-file-items-modal')
