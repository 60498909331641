import {
  WppTypography,
  WppMenuContext,
  WppActionButton,
  WppIconChevron,
  WppMenuGroup,
  WppListItem,
  WppInput,
  WppSkeleton,
  WppSpinner,
} from '@platform-ui-kit/components-library-react'
import { AnalyticsActionType } from '@wpp-open/core'
import { useRef, RefCallback, useCallback, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useParams, Link } from 'react-router-dom'
import { useSetState } from 'react-use'
import { HubListItem } from 'src/layout/header/hubSwitcher/components/HubListItem'
import { Instance, Props } from 'tippy.js'

import { useInfiniteHubsListApi } from 'api/hubs/infiniteQueries/useInfiniteHubsListApi'
import { useDeleteUserPinnedHub } from 'api/users/mutations/useDeleteUserPinnedHub'
import { useUpdateUserPinnedHub } from 'api/users/mutations/useUpdateUserPinnedHub'
import { Flex } from 'components/common/flex/Flex'
import { showRequestHubModal } from 'components/modal/requestHubModal/RequestHubModal'
import { ANALYTICS_EVENTS } from 'constants/analytics'
import { ApiQueryKeys } from 'constants/apiQueryKeys'
import { Delay } from 'constants/delay'
import { TableDefaults } from 'constants/table'
import { useDebounceFn } from 'hooks/useDebounceFn'
import { useHomeUrl } from 'hooks/useHomeUrl'
import { useInfiniteFetchNextPage } from 'hooks/useInfiniteFetchNextPage'
import { useRequestableHubs } from 'hooks/useRequestableHubs'
import styles from 'layout/header/Header.module.scss'
import { useCurrentTenantData } from 'providers/currentTenantData/CurrentTenantDataContext'
import { queryClient } from 'providers/osQueryClient/utils'
import { useOtherTenantsAndUserData } from 'providers/otherTenantsAndUserData/OtherTenantsAndUserDataContext'
import { useToast } from 'providers/toast/ToastProvider'
import { Hub } from 'types/hubs/hubs'
import { trackAnalytics } from 'utils/analytics'
import { isNonEmptyArray } from 'utils/common'

export const HubSwitcher = ({ useInBreadcrumbs }: { useInBreadcrumbs?: boolean }) => {
  const { t } = useTranslation()
  const { enqueueToast } = useToast()

  const { mutateAsync: pinHubHandler } = useUpdateUserPinnedHub()
  const { mutateAsync: unpinHubHandler } = useDeleteUserPinnedHub()

  const { hubId } = useParams()
  const { requestableHubs } = useRequestableHubs()
  const { currentTenant, defaultHub } = useCurrentTenantData()
  const {
    userDetails: { pinnedHubIds },
  } = useOtherTenantsAndUserData()

  const isHubPinned = useCallback(
    (id: string): boolean => {
      return !!pinnedHubIds?.length && pinnedHubIds.includes(id)
    },
    [pinnedHubIds],
  )
  const popperRef = useRef<Instance<Props>>()
  const refHubSearch = useRef<HTMLWppInputElement>(null)
  const [{ searchHub, loadMoreRef }, setState] = useSetState<{ searchHub: string; loadMoreRef: HTMLDivElement }>({
    searchHub: '',
    loadMoreRef: null!,
  })

  const {
    isLoading,
    data: hubs,
    isFetching,
    hasNextPage,
    fetchNextPage,
  } = useInfiniteHubsListApi({
    initialPageParam: { page: 1 },
    staleTime: TableDefaults.LoaderStaleTime,
    params: { searchName: searchHub, tenantId: currentTenant.id, sort: 'name' },
  })

  const [pinnedHubs, sortedHubs] = useMemo(() => {
    const pinnedHubs = [],
      sortedHubs = []
    for (const hub of hubs) {
      hub.id !== defaultHub!.id && (isHubPinned(hub.id) ? pinnedHubs.push(hub) : sortedHubs.push(hub))
    }
    return [pinnedHubs, sortedHubs]
  }, [hubs, defaultHub, isHubPinned])

  const hubsWithPredefinedDefaultHub = defaultHub ? [...hubs, defaultHub] : hubs
  const hasAccessToOtherHubs = hubs.some(hub => hub.id !== defaultHub?.id)

  const hasRequestableHubs = isNonEmptyArray(requestableHubs)
  const showDropdown = hasRequestableHubs || hubs.length > 1
  const showSwitcher = isNonEmptyArray(hubsWithPredefinedDefaultHub) || hasRequestableHubs

  const setLoadMoreRef: RefCallback<HTMLDivElement> = useCallback(
    node => {
      setState({ loadMoreRef: node! })
    },
    [setState],
  )

  useInfiniteFetchNextPage({
    loadMoreRef,
    isFetching,
    fetchNextPage,
    hasNextPage,
  })

  const setSearchDebounced = useDebounceFn(
    (key: string, search?: string) => setState({ [key]: search?.trim() || '' }),
    Delay.Search,
  )

  const handlePinnedStatus = async (hub: Hub) => {
    const handleSuccess = (message: string) => {
      queryClient.invalidateQueries({ queryKey: [ApiQueryKeys.CURRENT_USER] })
      enqueueToast({ message, type: 'success' })
    }
    popperRef.current?.show?.()
    try {
      if (isHubPinned(hub.id)) {
        await unpinHubHandler({ hubId: hub.id, tenantId: currentTenant.id })
        handleSuccess(t('os.header.hubs_selection.unpinned_successfully'))
      } else {
        await pinHubHandler({ hubId: hub.id, tenantId: currentTenant.id })
        handleSuccess(t('os.header.hubs_selection.pinned_successfully'))
      }
    } catch (error) {
      enqueueToast({
        message: t('os.common.errors.error'),
        type: 'error',
      })
    }
  }

  const homePageUrl = useHomeUrl()

  if (!showSwitcher) {
    return useInBreadcrumbs ? (
      <Link to={homePageUrl}>
        <WppTypography className={styles.hubsMenuButtonTitle} type="s-midi">
          {t('os.common.home')}
        </WppTypography>
      </Link>
    ) : null
  }

  // if there is only one hub available (the current one) and no requestable hubs, hide the dropdown
  if (!showDropdown) {
    // in case user is not on hub's homepage hide the switcher alltogether
    return hubId ? (
      <WppTypography className={styles.hubTitle} type="s-midi">
        {useInBreadcrumbs
          ? t('os.common.home')
          : hubsWithPredefinedDefaultHub.find(({ id }) => id === hubId)?.name ?? t('os.header.hubs_selection.title')}
      </WppTypography>
    ) : null
  }

  return (
    <WppMenuContext
      appendToListWrapper
      dropdownConfig={{
        onCreate: i => {
          popperRef.current = i
        },
        popperOptions: {
          strategy: 'fixed',
        },
      }}
    >
      <WppActionButton
        data-testid="header-hubs-dropdown"
        className={styles.hubsMenuTriggerButton}
        slot="trigger-element"
        variant="secondary"
        onClick={() => {
          setState({ searchHub: '' })
          refHubSearch.current?.setValue('')
          trackAnalytics({
            type: AnalyticsActionType.action,
            payload: ANALYTICS_EVENTS.LIST_OF_HUBS.ACTIONS.LIST,
          })
        }}
      >
        <WppTypography className={styles.hubsMenuButtonTitle} type="s-midi">
          {useInBreadcrumbs
            ? t('os.common.home')
            : hubsWithPredefinedDefaultHub.find(({ id }) => id === hubId)?.name ?? t('os.header.hubs_selection.title')}
        </WppTypography>

        <WppIconChevron className={styles.tenantMenuTriggerIcon} slot="icon-end" direction="down" />
      </WppActionButton>

      <div>
        {(hubs.length > 3 || searchHub) && (
          <WppMenuGroup withDivider>
            <WppListItem disabled className={styles.searchInputItem}>
              <WppInput
                ref={refHubSearch}
                slot="label"
                size="s"
                type="search"
                className={styles.searchInput}
                onWppChange={({ detail }) => setSearchDebounced('searchHub', detail.value)}
                placeholder={t('os.header.hubs_selection.search_placeholder')}
              />
            </WppListItem>
          </WppMenuGroup>
        )}

        {defaultHub && (
          <WppMenuGroup withDivider={hasAccessToOtherHubs}>
            <HubListItem
              isHubDefault
              hub={defaultHub}
              key={defaultHub.id}
              checked={hubId === defaultHub.id}
              onClickHandler={handlePinnedStatus}
            />
          </WppMenuGroup>
        )}

        {!!pinnedHubs.length && (
          <WppMenuGroup withDivider={hasAccessToOtherHubs}>
            {pinnedHubs.map(hub => {
              if (hub.id === defaultHub?.id) {
                return null
              } else {
                return (
                  <HubListItem
                    hub={hub}
                    isHubPinned
                    key={hub.id}
                    checked={hubId === hub.id}
                    onClickHandler={handlePinnedStatus}
                  />
                )
              }
            })}
          </WppMenuGroup>
        )}

        <WppMenuGroup withDivider={hasRequestableHubs}>
          {hasAccessToOtherHubs &&
            sortedHubs.map(hub => {
              if (hub.id === defaultHub?.id) {
                return null
              } else {
                return (
                  <HubListItem hub={hub} key={hub.id} checked={hubId === hub.id} onClickHandler={handlePinnedStatus} />
                )
              }
            })}

          {isLoading && (
            <WppListItem disabled className={styles.searchInputItem}>
              <WppSkeleton width={310} height={24} slot="label" data-testid="loading-skeleton" />
            </WppListItem>
          )}

          {hasNextPage && (
            <Flex justify="center" ref={setLoadMoreRef}>
              {isFetching && !isLoading && (
                <WppListItem disabled className={styles.searchInputItem}>
                  <WppSpinner size="s" slot="label" className={styles.loader} />
                </WppListItem>
              )}
            </Flex>
          )}
        </WppMenuGroup>

        {hasRequestableHubs && (
          <WppListItem
            onWppChangeListItem={() =>
              showRequestHubModal({
                title: t('os.request_access_modal.hub.title'),
                hubs: requestableHubs,
              })
            }
          >
            <span slot="label">
              {defaultHub || hasAccessToOtherHubs
                ? t('os.header.hubs_selection.request_access')
                : t('os.header.hubs_selection.request_access_no_hubs')}
            </span>
          </WppListItem>
        )}
        <div className={styles.menuMinWidthLimiter} />
      </div>
    </WppMenuContext>
  )
}
