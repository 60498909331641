import { RangeOf } from '@platform-ui-kit/components-library/dist/types/types/numberRange'

import { FileType } from 'pages/files/types'

export const FilesFormat = {
  music: ['.aif', '.cda', '.mid', '.mp3', '.mpa', '.ogg', '.wav', '.wma', '.wpl'],
  compressed: ['.7z', '.arj', '.rar', '.rpm', '.tar.gz', '.gz', '.z', '.zip'],
  data: ['.csv', '.dat', '.db', '.dbf', '.log', '.mdb', '.sav', '.sql', '.tar', '.xml'],
  spreadsheet: ['.ods', '.xls', '.xlsm', '.xlsx'],
  images: ['.ai', '.bmp', '.gif', '.ico', '.jpeg', '.jpg', '.png', '.ps', '.psd', '.svg', '.tif', '.tiff', '.webp'],
  documents: ['.doc', '.docx', '.odt', '.pdf', '.rtf', '.tex', '.txt', '.wpd'],
  presentations: ['.key', '.odp', '.pps', '.ppt', '.pptx'],
  videos: [
    '.3g2',
    '.3gp',
    '.avi',
    '.flv',
    '.h264',
    '.m4v',
    '.mkv',
    '.mov',
    '.mp4',
    '.mpg',
    '.rm',
    '.swf',
    '.vob',
    '.webm',
    '.wmv',
  ],
}

export const FilesColorFormat: Record<FileType | 'other', Exclude<RangeOf<9>, 0>> = {
  music: 1,
  compressed: 2,
  data: 3,
  images: 4,
  presentations: 5,
  spreadsheet: 6,
  videos: 7,
  documents: 8,
  other: 9,
}

export const enum FilesListUrlParams {
  FILE_ID = 'fileId',
  FILE_NOT_FOUND = 'fileNotFound',
  FOLDER_NOT_FOUND = 'folderNotFound',
}
